<template>

    <section class="cell-phone input-component">

        <label class="label">{{ label }} <span v-if="isForced" class="forced">*</span></label>

		<section class="input-with-prefix" :class="{error: errorMessage}">
			<span class="prefix">{{ prefix }}</span>
				<input
					class="cell-phone"
					v-model="fieldData"
					@blur="checkValue"
					placeholder="۹۱۲۳۴۵۶۷۸۹"
					ref="fieldData"
					:disabled=false>
		</section>

		<p v-show="errorMessage" class="input-error">{{ errorMessage }}</p>
    </section>

</template>

<script>

const dataPattern = /^989[0-9]{9}$/

export default {

	name: 'cell-phone',

	props: {
		label: {
			type: String,
			default: 'شماره تلفن همراه',
		},
		name: {
			type: String,
			default: '',
		},
		initValue: {
			type: String,
			default: '',
		},
		isForced: {
			type: Boolean,
			default: false,
		},
		showHelp: {
			type: Boolean,
			default: true,
		}
	},

	data() {
		return {
			fieldData: this.toPersianDigits(this.onlyDigits(this.initValue.replace(/^(98|0)/, ''))),
			errorMessage: '',
		}
	},

	computed: {
		prefix: function() {
			return '۹۸'
		},

		fullData: function() {
			return this.toEnglishDigits(this.prefix + this.fieldData).replace(/^980/, '98')
		},

		fieldName() {
			return (this.name === '') ? this.label : this.name
		},
	},

	methods: {
		checkValue() {
            this.errorMessage = ''
            if (this.fieldData === '') {
                if (this.isForced)
                    this.errorMessage = '«' + this.fieldName + '» نباید خالی باشد'
            }
            else {
				if (! this.isValid(this.fullData)) {
					this.errorMessage = '«' + this.fieldName + '» وارد شده نادرست است'
				}
            }
		},

		setValue(value) {
			this.fieldData = value
		},

		setError(message) {
			this.errorMessage = message
		},

		isValid(value) {

			if (value == null) {
				return false
			}
			if (!dataPattern.test(value)) {
				return false
			}
			return true
		},

		getInternationalFormat() {
			return this.isValid(this.fullData) ? this.toPersianDigits(this.fullData) : ''
		},

		getNationalFormat() {
			return this.isValid(this.fullData) ? this.toPersianDigits(this.fullData.replace(/^98/, '0')) : ''
		},

		hasError() {
			return (this.errorMessage != '')
		},

		toString() {
			return this.fieldData
		},

		focus() {
			this.$refs.fieldData.focus()
		},
	},

	watch: {
        fieldData(value) {
            this.fieldData = this.toPersianDigits(this.onlyDigits(value))
        }
	}
}
</script>