<template>
    <div>
        <webpay-header-landing/>

        <main class="container">
            <div class="dummy-band">
            </div>
            
            <div class="landing-title">
                <div class="row" style="display: block;">
                    <div class="webpay-first-look">
                        <div class="one-half column">
                            <div class="landing-logo">
                                <img src="img/bahamta/logo-gray.svg?2"/>
                            </div>
                            <div>
                                <h2>درگاه پرداخت اینترنتی باهمتا</h2>
                                <h1 style="font-weight: bold;"><span  class="bullet"></span>وب‌پی (WebPay)</h1>
                            </div>
                            <div class="center-horizontally">
                                <p class="subtitle">
                                    درگاه پرداخت اینترنتی با تمرکز بر «<strong>امنیت</strong>» و «<strong>سادگی</strong>»
                                </p>
                                <button
                                    @click="loggedIn ? gotoPanel() : gotoLogin()">
                                    ساخت درگاه پرداخت
                                </button>
                            </div>
                        </div>

                        <div class="on-mobile one-half column landing-image">
                            <img src="img/landing/mobile/high.webp">
                        </div>

                        <div class="on-desktop one-half column landing-image">
                            <img src="img/landing/computer/high.webp">
                        </div>
                    </div>
                </div>

                <div class="row stick-to-bottom u-full-width">
                    <a v-scroll-to="{el: '#what-is-webpay', duration: 500, offset: -90}" href="#what-is-webpay" class="anchor arrow-bottom-box">
                        <div class="webpay-guide">
                            <img src="/icon/base-play-black-48dp.png" class="bounce-up-and-down"/>
                        </div>
                    </a>
                </div>
            </div>

            <div class="row">
                <div class="twelve columns webpay-description center-horizontally">
                    <div id="what-is-webpay">
                        <p class="title">وب‌پی چیست؟</p>
                        <p>
                            <strong>وب‌پی</strong> بستری است برای پرداخت اينترنتی یا همان پرداخت هزینه و خرید بصورت غیرحضوری كه از طريق آن صاحبان كارت‌های بانكی عضو شتاب می‌توانند با استفاده از مشخصات كارت بانكی خود نسبت به خريد كالا يا خدمات از طریق درگاه‌های پرداخت اینترنتی اقدام نمايند. فرآیند دریافت و پرداخت پول در کسب‌وکارهای آنلاین باید در یک بستر امن و راحت صورت گیرد.
                            <br/>
                            <br/>
                            &laquo;وب&zwnj;پی&raquo; به دارندگان کسب&zwnj;وکارهای اینترنتی کمک می&zwnj;کند تا نیاز خود را برای گرفتن درگاه پرداخت اینترنتی و دریافت پول از مشتریان برطرف سازند. &laquo;<strong>امنیت</strong>&raquo; و &laquo;<strong>آسانی</strong>&raquo; از اولویت&zwnj;های اصلی در &laquo;وب&zwnj;پی&raquo; هستند. 
                            <br/>
                            <br/>
                            <strong>امنیت</strong> به معنای اینکه کسی نتواند به عنوان مشتری، صاحب کسب&zwnj;وکار را فریب داده و بدون پرداخت پول از کالا یا خدمات وی استفاده کند؛ و <strong>آسانی</strong> به این معنی که مشتریان عادی بتوانند بدون مشکل و در زمانی کوتاه پول خود را پرداخت کرده و سفارش خود را در سایت مقصد ثبت کنند.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <h3 class="twelve columns">
                    <span class="bullet"></span>سریع و آسان <strong class="colorized">درگاه پرداخت اینترنتی</strong> بسازید
                </h3>
            </div>

            <div class="row steps">
                <div class="one-third column">
                    <div class="box one">
                        <strong>ایجاد</strong> حساب کاربری در وب‌پی
                        <div class="logo center-vertically">
                            <img src="/img/landing/steps/1.svg?v=1">
                        </div>
                    </div>
                </div>
                <div class="one-third column">
                    <div class="box two">
                        ثبت اطلاعات کسب و کار و دریافت <strong>کلید الکترونیکی</strong>
                        <div class="logo center-vertically">
                            <img src="/img/landing/steps/2.svg?v=1">
                        </div>
                    </div>
                </div>
                <div class="one-third column">
                    <div class="box three">
                        دریافت <strong>افزونه</strong> یا <strong>API</strong> وب‌پی و پیاده‌سازی آن
                        <div class="logo center-vertically">
                            <img src="/img/landing/steps/3.svg?v=1">
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div id="income-from-webpay" class="row">
                <div class="twelve columns webpay-description center-horizontally">
                    <div>
                        <p class="title">کسب درآمد از وب‌پی</p>

                        <p>
                            <strong>با معرفی درگاه پرداخت وب‌پی به دیگران، در کارمزد ما شریک شوید.</strong>
                            <br/>
                            <br/>
                            کسب درآمد برای شما زمانی آغاز می شود که مشتری جدید درگاه پرداخت وب‌پی، از طریق لینک اختصاصی «دعوت از دیگران» عضو وب‌پی شود و یا در زمان تکمیل اطلاعات کاربری، شماره موبایل شما را به عنوان «معرف» ثبت کند.
                            هیچ محدودیتی برای تعداد افرادی که از طرف شما دعوت می‌شوند وجود ندارد و میزان کارمزدی که برای «معرف» در نظر گرفته شده است ۲۰درصد مبلغ کارمزد کسر شده از هر تراکنش و به صورت مادام‌العمر است.
                            برای دریافت اطلاعات بیشتر، به صفحه اختصاصی
                            <router-link to="/affiliation">کسب درآمد</router-link>
                            وب‌پی مراجعه کنید.
                        </P>

                    </div>
                </div>
            </div> -->

            <div class="row plugins">
                <div class="one-half column">
                    <router-link :to="{name: 'plugins'}" >
                        <div class="plugin-box">
                            <div class="logo">
                                <img src="/img/landing/plugins/plugin-icon.webp">
                            </div>
                            <p text-align="center">
                                افزونه‌ها
                            </p>

                            <!-- <div class="video-url">
                                <a class="center-horizontally" href="https://google.com">
                                    مشاهده ویدئو <img class="play-button" src="img/landing/plugins/play.svg">
                                </a>
                            </div> -->
                        </div>
                    </router-link>
                </div>
                <div class="one-half column">
                    <router-link to="/doc/api">
                        <div class="plugin-box">
                            <div class="logo">
                                <img src="/img/landing/plugins/api.svg">
                            </div>
                            <p text-align="center">
                                <strong>API</strong> وب‌پی
                            </p>
                            <!-- <div class="video-url">
                                <a class="center-horizontally" href="https://google.com">
                                    مشاهده ویدئو <img class="play-button" src="img/landing/plugins/play.svg">
                                </a>
                            </div> -->
                        </div>
                    </router-link>
                </div>
            </div>

        </main>

        <webpay-footer-landing class="push-down-for-header" />
    </div>
</template>

<script>
import WebpayHeaderLanding from "../headers/WebpayHeaderLanding.vue";
import WebpayFooterLanding from "../footers/WebpayFooterLanding.vue";

export default {
    name: "webpay-landing-main",
    components: {
        WebpayHeaderLanding,
        WebpayFooterLanding
    },
    methods: {
        gotoLogin() {
            this.$router.push("/login")
        },
        gotoPanel() {
            this.$router.push("/panel")
        }
    },
    computed: {
        loggedIn: function() {
            return this.$store.getters.loggedIn
        }
    }
}
</script>

<style scoped>
html {
    scroll-behavior: smooth;
}

.landing-title {
    text-align: center;
    margin-top: 75px;
    min-height: calc(100vh - 75px);
    position: relative;
}

.landing-title .webpay-first-look {
    height: calc(100vh - 150px);
}

.landing-title h1 {
    font-weight: bold;
    font-size: 26px;
    text-align: center;
}

.landing-title h2 {
    font-weight: normal;
    font-size: 22px;
    margin-bottom: 8px;
    text-align: center;    
}

.landing-title .subtitle {
    padding: 10%;
    text-align: center;
}

.landing-title .landing-image {
    justify-content: center;
}

.landing-title .landing-image img {
    margin: 32px 0;
    width: 100%;
    height: 100%;
}

.landing-title .webpay-guide {
    display: flex;
    justify-content: center;
    /* margin: 16px; */
    bottom: 30px;
    margin-top: -70px;
    padding-top: 70px;
}

.landing-title .webpay-guide a {
    color: var(--text-primary);
}

.landing-logo {
    width: 40%;
    height: 40%;
    display: inline-flex;
    justify-content: center;
}

#what-is-webpay p {
    margin-bottom: 0;
}

.webpay-description {
    margin-top: 30px;
    margin-bottom: 64px;
}

.webpay-description div {
    border: 3px solid var(--color-primary);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    max-width: 700px;
}

.webpay-description p {
    margin: 8px;
    padding: 16px;
    text-align: justify;
}

.webpay-description p.title {
    margin: 0;
    padding: 5px;
    text-align: center;
    background: var(--color-primary);
    color: white;
    font-size: 1.3em;
}

h3 {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
}

#income-from-webpay p {
    margin-bottom: 0;
}

.video-url a {
    padding: 8px;
}

.steps .box {
    min-height: 150px;
}

.plugin-box p {
    margin: 0;
    text-align: center;
    color: black;
    padding: 10px;
}

@media (min-width: 550px) {
    .landing-title .webpay-first-look {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }

    .landing-title .webpay-guide {
        bottom: 50px;
        margin-top: -100px;
        padding-top: 0px;
    }

    .plugins .one-third.column {
        width: 48%;
    }
}

@media (min-width: 750px) {
    .plugins .one-third.column {
        width: 30.6666666667%
    }
}

</style>