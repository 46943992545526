<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  created() {
    let referralCode = this.$route.query.rc
    if (referralCode != null) {
      this.$store.commit('setReferralCode', referralCode)
    }
  }
}
</script>

<style>
</style>
