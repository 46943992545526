import axios from "axios"
import {loaderFactory} from "./utils"

export default {
  namespaced: true,
  state: {
    // 'getting, 'posting', 'ok',
    // 'error', 'not_authorized', 'no_profile'
    status: '',
    data: null,
  },
  mutations: {
    set(state, profile) {
      state.status = 'ok'
      state.data = profile
    },
    setStatus(state, profileStatus) {
      state.status = profileStatus
    },
    remove(state) {
      state.status = ''
      state.data = null
    },
    update(state, profile) {
      state.status = 'ok'
      state.data = profile
    },
  },
  getters: {
    noProfile(state) {
      return ['no_profile', 'NO_PROFILE'].includes(state.status)
    },
    isLoading(state) {
      return state.status === 'getting'
    },
    isUpdating(state) {
      return state.status === 'posting'
    },
    firstname(state) {
      if (state.isLoading || state.data == null) return ''
      return state.data.first_name
    },
    lastname(state) {
      if (state.isLoading || state.data == null) return ''
      return state.data.last_name
    },
    fullname(state) {
      if (state.isLoading || state.data == null) return ''
      return state.data.first_name + ' ' + state.data.last_name
    },
    nationalcode(state) {
      if (state.isLoading || state.data == null) return ''
      return state.data.nid
    },
    birthdate(state) {
      if (state.isLoading || state.data == null) return ''
      return state.data.birthdate
    },
    iban(state) {
      if (state.isLoading || state.data == null) return ''
      return state.data.iban
    },
    email(state) {
      if (state.isLoading || state.data == null) return ''
      return state.data.email
    },
    createTime(state) {
      if (state.isLoading || state.data == null) return new Date(0)
      return state.data.create_time
    },
    affiliationCode(state) {
      if (state.isLoading || state.data == null) return ''
      return state.data.ref_code
    },
  },
  actions: {
    load: loaderFactory('/api/owner/get_profile'),
    async update({commit, rootGetters}, params) {
      commit('setStatus', 'posting')
      params.key = rootGetters.sessionKey
      await axios.get('/api/owner/set_profile', {params})
        .catch(error => {
          commit('setStatus', 'error')
          if (error.response) {
            alert(error.response.config.url + " => " + error.response.data)
          } else if (error.request) {
            alert(error.request.config.url + " => no response")
          } else {
            alert(error.message)
          }
        }).then(response => {
          if (response == null || response.data == null)
            return null

          return response.data
        }).then(data => {
          if (data == null) {
            commit('setStatus', '')
            throw 'no_data'
          }

          if (data.ok) {
            commit('update', data.result)
            return data.result
          }

          switch (data.error) {
            case 'NOT_AUTHORIZED':
              commit('setStatus', 'not_authorized')
              break
            default:
              commit('setStatus', 'ok')
              throw data.error
          }
        })
    },
  },
  modules: {
    affiliation: {
      namespaced: true,
      state: {
        status: '',
        data: null,
      },
      mutations: {
        set(state, data) {
          state.data = data
        },
        setStatus(state, status) {
          state.status = status
        },
      },
      getters: {
        data(state) {
          return state.data
        }
      },
      actions: {
        load: loaderFactory('/api/owner/report_affiliation')
      }
    }
  }
}