import axios from "axios"

export {
  loaderFactory
}

function loaderFactory(endpoint) {
  return async function ({commit, rootGetters}, params) {
    if (params == null) {
      params = {}
    }

    params.key = rootGetters.sessionKey
    commit('setStatus', 'getting')
    await axios.get(endpoint, {params})
      .catch(error => {
        commit('setStatus', 'error')
        if (error.response) {
          alert(error.response.config.url + " => " + error.response.data)
        } else if (error.request) {
          alert(error.request.config.url + " => no response")
        } else {
          alert(error.message)
        }
      }).then(response => {
        if (response == null || response.data == null)
          return null

        return response.data
      }).then(data => {
        if (data == null) {
          commit('setStatus', '')
          return
        }

        if (data.ok) {
          commit('set', data.result)
          return
        }

        switch (data.error) {
          case 'NOT_AUTHORIZED':
            commit('setStatus', 'not_authorized')
            break
          case 'NO_PROFILE':
            commit('setStatus', 'no_profile')
            break
          default:
            commit('setStatus', 'error')
            alert(data.error)
        }
      })
  }
}