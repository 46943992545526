var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('webpay-header-landing'),_c('main',{staticClass:"container"},[_c('div',{staticClass:"dummy-band"}),_c('div',{staticClass:"landing-title"},[_c('div',{staticClass:"row",staticStyle:{"display":"block"}},[_c('div',{staticClass:"webpay-first-look"},[_c('div',{staticClass:"one-half column"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"center-horizontally"},[_vm._m(2),_c('button',{on:{"click":function($event){_vm.loggedIn ? _vm.gotoPanel() : _vm.gotoLogin()}}},[_vm._v(" ساخت درگاه پرداخت ")])])]),_vm._m(3),_vm._m(4)])]),_c('div',{staticClass:"row stick-to-bottom u-full-width"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({el: '#what-is-webpay', duration: 500, offset: -90}),expression:"{el: '#what-is-webpay', duration: 500, offset: -90}"}],staticClass:"anchor arrow-bottom-box",attrs:{"href":"#what-is-webpay"}},[_vm._m(5)])])]),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"row plugins"},[_c('div',{staticClass:"one-half column"},[_c('router-link',{attrs:{"to":{name: 'plugins'}}},[_c('div',{staticClass:"plugin-box"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":"/img/landing/plugins/plugin-icon.webp"}})]),_c('p',{attrs:{"text-align":"center"}},[_vm._v(" افزونه‌ها ")])])])],1),_c('div',{staticClass:"one-half column"},[_c('router-link',{attrs:{"to":"/doc/api"}},[_c('div',{staticClass:"plugin-box"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":"/img/landing/plugins/api.svg"}})]),_c('p',{attrs:{"text-align":"center"}},[_c('strong',[_vm._v("API")]),_vm._v(" وب‌پی ")])])])],1)])]),_c('webpay-footer-landing',{staticClass:"push-down-for-header"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-logo"},[_c('img',{attrs:{"src":"img/bahamta/logo-gray.svg?2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("درگاه پرداخت اینترنتی باهمتا")]),_c('h1',{staticStyle:{"font-weight":"bold"}},[_c('span',{staticClass:"bullet"}),_vm._v("وب‌پی (WebPay)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle"},[_vm._v(" درگاه پرداخت اینترنتی با تمرکز بر «"),_c('strong',[_vm._v("امنیت")]),_vm._v("» و «"),_c('strong',[_vm._v("سادگی")]),_vm._v("» ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-mobile one-half column landing-image"},[_c('img',{attrs:{"src":"img/landing/mobile/high.webp"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-desktop one-half column landing-image"},[_c('img',{attrs:{"src":"img/landing/computer/high.webp"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"webpay-guide"},[_c('img',{staticClass:"bounce-up-and-down",attrs:{"src":"/icon/base-play-black-48dp.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"twelve columns webpay-description center-horizontally"},[_c('div',{attrs:{"id":"what-is-webpay"}},[_c('p',{staticClass:"title"},[_vm._v("وب‌پی چیست؟")]),_c('p',[_c('strong',[_vm._v("وب‌پی")]),_vm._v(" بستری است برای پرداخت اينترنتی یا همان پرداخت هزینه و خرید بصورت غیرحضوری كه از طريق آن صاحبان كارت‌های بانكی عضو شتاب می‌توانند با استفاده از مشخصات كارت بانكی خود نسبت به خريد كالا يا خدمات از طریق درگاه‌های پرداخت اینترنتی اقدام نمايند. فرآیند دریافت و پرداخت پول در کسب‌وکارهای آنلاین باید در یک بستر امن و راحت صورت گیرد. "),_c('br'),_c('br'),_vm._v(" «وب‌پی» به دارندگان کسب‌وکارهای اینترنتی کمک می‌کند تا نیاز خود را برای گرفتن درگاه پرداخت اینترنتی و دریافت پول از مشتریان برطرف سازند. «"),_c('strong',[_vm._v("امنیت")]),_vm._v("» و «"),_c('strong',[_vm._v("آسانی")]),_vm._v("» از اولویت‌های اصلی در «وب‌پی» هستند. "),_c('br'),_c('br'),_c('strong',[_vm._v("امنیت")]),_vm._v(" به معنای اینکه کسی نتواند به عنوان مشتری، صاحب کسب‌وکار را فریب داده و بدون پرداخت پول از کالا یا خدمات وی استفاده کند؛ و "),_c('strong',[_vm._v("آسانی")]),_vm._v(" به این معنی که مشتریان عادی بتوانند بدون مشکل و در زمانی کوتاه پول خود را پرداخت کرده و سفارش خود را در سایت مقصد ثبت کنند. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('h3',{staticClass:"twelve columns"},[_c('span',{staticClass:"bullet"}),_vm._v("سریع و آسان "),_c('strong',{staticClass:"colorized"},[_vm._v("درگاه پرداخت اینترنتی")]),_vm._v(" بسازید ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row steps"},[_c('div',{staticClass:"one-third column"},[_c('div',{staticClass:"box one"},[_c('strong',[_vm._v("ایجاد")]),_vm._v(" حساب کاربری در وب‌پی "),_c('div',{staticClass:"logo center-vertically"},[_c('img',{attrs:{"src":"/img/landing/steps/1.svg?v=1"}})])])]),_c('div',{staticClass:"one-third column"},[_c('div',{staticClass:"box two"},[_vm._v(" ثبت اطلاعات کسب و کار و دریافت "),_c('strong',[_vm._v("کلید الکترونیکی")]),_c('div',{staticClass:"logo center-vertically"},[_c('img',{attrs:{"src":"/img/landing/steps/2.svg?v=1"}})])])]),_c('div',{staticClass:"one-third column"},[_c('div',{staticClass:"box three"},[_vm._v(" دریافت "),_c('strong',[_vm._v("افزونه")]),_vm._v(" یا "),_c('strong',[_vm._v("API")]),_vm._v(" وب‌پی و پیاده‌سازی آن "),_c('div',{staticClass:"logo center-vertically"},[_c('img',{attrs:{"src":"/img/landing/steps/3.svg?v=1"}})])])])])
}]

export { render, staticRenderFns }