<template>
  <div id="css-container">
    <router-view></router-view>
  </div>
</template>

<script>
  import '@/assets/fontawesome/css/all.min.css'

  export default {
    name: "CssContainer"
  }
</script>

<style>
  @import url(
  'https://cdn.jsdelivr.net/npm/vazir-font@26.0.2/dist/font-face.css');

  @import url('/css/skeleton-min-rtl.css');
  @import url('/css/tabbed.css');
  @import url('/css/document.css');

  /******************** Global Variables */

  :root {
    --color-primary: #49b4af;

    --text-primary: #424242;
    --text-secondary: #808080;
    --text-attention: #d3ad03;
    --text-placeholder: #cccccc;

    --pale-teal: #61bab5;
    --pale-teal-light: #7dcfcb;
    --pale-teal-dark: #3d7572;

    --report-title: #4585c1;
    --report-header: #8db9e2;

    --color-button: #49b4af;
    --color-button-label: white;
    --color-border: #49b4af;

    --color-button-hover: #49b4af;
    --color-button-label-hover: white;
    --color-border-hover: #4585c1;

    --color-button-active: #49b4af;
    --color-button-label-active: white;
    --color-border-active: #4585c1;

    --color-button-disable: lightgray;
    --color-button-label-disable: white;
    --color-border-disable: lightgray;

    --sapphire: #242993;
    --sapphire-light: #2e35b9;
    --sapphire-dark: #14174f;

    --color-table-border: #444444;
    --greyish-brown: #4d4d4d;
    --bright-cyan: #42ffff;
    --macaroni-and-cheese: #f6b437;
    --bloody-red: #f15151;

    --input-prefix: #d0d0d0;

    --desktop-h1-font-size: 28px;
    --desktop-h2-font-size: 24px;
    --desktop-h3-font-size: 21px;
    --desktop-p-font-size: 16px;
    --desktop-button-font-size: 16px;

    --mobile-h1-font-size: 24px;
    --mobile-h2-font-size: 20px;
    --mobile-h3-font-size: 18px;
    --mobile-p-font-size: 16px;
    --mobile-button-font-size: 16px;
  }

  #css-container *,
  #css-container *:before,
  #css-container *:after {
    letter-spacing: 0;
    box-sizing: border-box;
  }


  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    var(--text-placeholder);
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    var(--text-placeholder);
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    var(--text-placeholder);
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    var(--text-placeholder);
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color:    var(--text-placeholder);
  }

  ::placeholder { /* Most modern browsers support this now. */
    color:    var(--text-placeholder);
  }

  /******************** Global Configurations */

  #app #css-container {
    font-family: 'Vazir', 'Georgia';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    direction: rtl;
    color: var(--text-primary);
    overflow-x: auto;
  }

  #css-container .icon {
    padding: 4px 4px;
    width: 38px;
  }

  /******************** Buttons */

  /*
  #css-container .button,
  #css-container button,
  #css-container button.action,
  #css-container input[type="submit"],
  #css-container input[type="reset"],
  #css-container input[type="button"] {
    color: var(--color-button-label);
    font-family: inherit;
    font-size: inherit;
    font-weight: normal;
    letter-spacing: 0rem;
    background-color: var(--color-button);
    border-width: 3px;
    border-color: var(--color-button);
    border-radius: 50px;
    margin: 0.5em
  }

  #css-container .button:hover,
  #css-container button:hover,
  #css-container button.action:hover,
  #css-container input[type="submit"]:hover,
  #css-container input[type="reset"]:hover,
  #css-container input[type="button"]:hover,
  #css-container .button:focus,
  #css-container button:focus,
  #css-container button.action:focus,
  #css-container input[type="submit"]:focus,
  #css-container input[type="reset"]:focus,
  #css-container input[type="button"]:focus {
    color: var(--color-button-label-hover);
    background-color: var(--color-button);
    border-width: 2px;
    border-color: var(--color-button);
    outline: 0;
  }

  #css-container .button:active,
  #css-container button:active,
  #css-container button.action:active,
  #css-container input[type="submit"]:active,
  #css-container input[type="reset"]:active,
  #css-container input[type="button"]:active {
    color: var(--color-button-label-hover);
    background-color: var(--color-button);
    border: 2px;
    border-color: var(--color-button);
    box-shadow: none;
    transform: translateY(2px);
    outline: 0;
  } */

  #css-container .button,
  #css-container button,
  #css-container button.action,
  #css-container input[type="submit"],
  #css-container input[type="reset"],
  #css-container input[type="button"] {
    color: var(--color-button-label);
    font-family: inherit;
    font-size: inherit;
    font-weight: normal;
    letter-spacing: 0rem;
    background-color: var(--color-button);
    border-width: 2px;
    border-radius: 50px;
    border-color: var(--color-border);
    /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); */
    margin: 0.5em;
  }

  #css-container .button:hover,
  #css-container button:hover,
  #css-container button.action:hover,
  #css-container input[type="submit"]:hover,
  #css-container input[type="reset"]:hover,
  #css-container input[type="button"]:hover,
  #css-container .button:focus,
  #css-container button:focus,
  #css-container button.action:focus,
  #css-container input[type="submit"]:focus,
  #css-container input[type="reset"]:focus,
  #css-container input[type="button"]:focus {
    color: var(--color-button-label-hover);
    background-color: var(--color-button-hover);
    border-width: 2px;
    border-color: var(--color-border-hover);
    outline: 0;
  }

  #css-container button.disabled,
  #css-container .button.disabled,
  #css-container input[type="submit"]:disabled,
  #css-container input[type="reset"]:disabled,
  #css-container input[type="button"]:disabled {
    color: var(--color-button-label-disable);
    background-color: var(--color-button-disable);
    border-width: 2px;
    border-color: var(--color-border-disable);
  }

  #css-container .button:active,
  #css-container button:active,
  #css-container button.action:active,
  #css-container input[type="submit"]:active,
  #css-container input[type="reset"]:active,
  #css-container input[type="button"]:active {
    color: var(--color-button-label-active);
    background-color: var(--color-button-active);
    border-width: 2px;
    border-color: var(--color-border-active);
    box-shadow: none;
    transform: translateY(2px);
    outline: 0;
  }

  /* #css-container button,
  #css-container button:focus,
  #css-container button:hover,
  #css-container button:active {
    background-color: var(--pale-teal);
    border-radius: 5px;
    border: 0;
    border-bottom: 3px solid var(--pale-teal-dark);
    text-decoration: none;
    letter-spacing: 0;
    color: white;
    font-size: var(--mobile-button-font-size);

    display: inline-flex;
    align-items: center;
  } */

  #css-container button.circle {
    height: 10mm;
    width: 10mm;
    padding: 0;
    border-radius: 50%;
    border: 0 solid black;
    background-color: var(--color-primary);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }

  #css-container button.circle.destructive {
    background-color: red;
  }

  #css-container button.circle img {
    margin: 0 auto;
    padding: 4px;
  }

  #css-container .action-band {
    display: flex;
    flex-direction: row;
  }

  #css-container button img,
  #css-container button:focus img,
    /*#css-container button:hover img,*/
  #css-container button:active img {
    width: 30px;
    height: 30px;
    margin: auto;
  }

  /* #css-container button:hover {
    background-color: var(--pale-teal-light);
  }

  #css-container button:active {
    background-color: var(--pale-teal-dark);
  } */

  #css-container button.secondary,
  #css-container button.secondary:focus,
  #css-container button.secondary:hover,
  #css-container button.secondary:active {
    background-color: var(--sapphire);
    border-bottom: 3px solid var(--sapphire-dark);
  }

  #css-container button.secondary:hover {
    background-color: var(--sapphire-light);
  }

  #css-container button.secondary:active {
    background-color: var(--sapphire-dark);
  }

  #css-container button.loading {
    position: relative;
  }

  #css-container button.loading::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 8px;
    right: 5px;
    background-color: var(--pale-teal-dark);
    border-radius: 100%;
    opacity: 0.33;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  #css-container button {
    display: flex;
    align-items: center;
  }

  #css-container button.action,
  #css-container button.action:focus,
  #css-container button.action:hover,
  #css-container button.action:active {
    padding: 0 16px;
  }

  #css-container button .title {
    padding: 0 4px;
  }

  #css-container button .icon {
    padding: 0 4px;
  }

  #css-container .clickable {
    cursor: pointer;
  }

  #css-container .fas:before {
    margin-left: 5px;
  }

  #css-container .fas:after {
    margin-right: 5px;
  }


  @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    50% { -webkit-transform: scale(1.0) }
    100% { -webkit-transform: scale(0) }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 50% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      } 100% {
          -webkit-transform: scale(0);
          transform: scale(0);
        }
  }

  /* @media (min-width: 550px) {
    #css-container button,
    #css-container button:focus,
    #css-container button:hover,
    #css-container button:active {
      font-size: var(--desktop-button-font-size);
    }
  } */

  /******************** Bullets */

  #css-container .bullet {
    position: relative;
    margin: 8px;
  }

  #css-container .bullet::before,
  #css-container .bullet::after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: -15px;
  }

  #css-container .bullet::before {
    border: 5px solid var(--color-primary);
    border-radius: 100%;
    height: 15px;
    width: 16px;
  }

  #css-container .bullet::after {
    height: 15px;
    width: 8px;
    background-color: white;
  }

  /******************** Headers */

  #css-container h1, #css-container h2, #css-container h3 {
    margin-top: 32px;
  }

  #css-container h1 {
    font-size: var(--mobile-h1-font-size);
  }

  #css-container h2 {
    font-size: var(--mobile-h2-font-size);
  }

  #css-container h3 {
    font-size: var(--mobile-h3-font-size);
  }

  @media (min-width: 550px) {
    #css-container h1 {
      font-size: var(--desktop-h1-font-size);
    }

    #css-container h2 {
      font-size: var(--desktop-h2-font-size);
    }

    #css-container h3 {
      font-size: var(--desktop-h3-font-size);
    }
  }

  /******************** Paragraphes */

  #css-container .content {
    overflow-wrap: break-word;
  }

  #css-container p {

  }

  #css-container .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  /******************** Links */

  /******************** Forms */

  #css-container form {
    color: var(--text-primary);
    padding: 16px;
    border: 3px solid var(--color-primary);
    border-radius: 10px;
    direction: rtl;
  }

  #css-container form h1 {
    text-align: center;
    margin: 8px 0 16px 0;
    font-size: 22px;
    font-weight: 500;
    color: var(--text-primary);
  }

  #css-container form section {
    margin-bottom: 0;
    position: relative;
  }

  #css-container form label {
    color: var(--text-secondary);
    text-align: right;
  }

  #css-container form label a {
    float: left;
  }

  #css-container form label .forced {
    color: red;
  }

  #css-container form input:disabled,
  #css-container form .input-with-prefix.disabled {
    background: white;
    border: 1px solid lightgray;
  }

  #css-container form input {
    color: var(--text-primary);
    padding: 5px 8px;
    margin: 0;
    width: 100%;
    outline: none;
  }

  #css-container form input,
  #css-container form .input-with-prefix {
    border: 1px solid var(--color-primary);
    border-radius: 5px;
  }

  #css-container .input-component {
    margin-bottom: 25px;
  }

  #css-container form input.error,
  #css-container form .input-with-prefix.error,
  #css-container form .input-with-prefix.disabled .input {
    border: 1px solid var(--bloody-red);
  }

  #css-container form .input-with-prefix {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-collapse: collapse;
    margin-bottom: 0;
  }

  #css-container form .input-with-prefix .prefix {
    color: black;
    direction: ltr;
    padding: 5px 8px;
    border-radius: 5px 0 0 5px;
    background-color: var(--input-prefix)
  }

  #css-container form .input-with-prefix input {
    border: 0;
    border-radius: 0 5px 5px 0;
    margin: 0;
  }

  #css-container form .input-error {
    color: var(--bloody-red);
    text-align: right;
    margin: 0;
    padding: 0;
    left: 0;
    bottom: 0;
  }

  #css-container .jalaali-date .input-group.error input,
  #css-container .full-name .input-group.error input {
    border: 1px solid var(--bloody-red);
  }

  #css-container .jalaali-date .input-group,
  #css-container .full-name .input-group {
    display: flex;
    justify-content: space-between;
  }

  #css-container .jalaali-date .input-group {
    direction: ltr;
  }

  #css-container .jalaali-date .input-group > * {
    width: 30%;
  }

  #css-container .full-name .input-group > * {
    width: 47.5%;
  }

  #css-container .on-mobile {
    display: flex;
  }

  #css-container .on-desktop {
    display: none;
  }

  #css-container .justified-row {
    display: inline-flex;
    align-items: center;
  }

  #css-container .center-horizontally {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  #css-container .center-vertically {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }

  #css-container .center-align-cell {
    text-align: center;
  }

  #css-container .minimal {
    display: flex;
    width: auto;
  }

  /* This is useful for fitting a block in the remaining width. Do not use with 'column' class. */
  #css-container .fit-remaining {
    width: auto;
    overflow: hidden;
  }

  #css-container .main-box {
    margin: 3rem 2rem;
  }

  #css-container .flex-fill {
    flex-grow: 1;
  }

  #css-container a {
    text-decoration: none;
    color: #49B4AF;
  }

  #css-container a.action {
    color: white;
    background-color: #49B4AF;
    padding: 8px 16px;
    border-radius: 8px;
  }

  #css-container .link-hover-animation .animated {
    position: relative;
    color: var(--color-primary);
    background-color: white;
    text-decoration: none;
    text-align: center;
    font-weight: 400;
  }

  #css-container .link-hover-animation .animated:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 15px;
    left: 0;
    background-color: var(--color-primary);
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }

  #css-container .link-hover-animation .animated:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  #css-container a.anchor {
    text-decoration: none;
    color: var(--text);
  }

  #css-container a:link, #css-container a:visited {
    color: #49b4af;
    text-decoration: none;
    transition: color .25s ease, fill .25s ease;
  }

  #css-container .arrow-bottom-box {
    width: 25px;
    height: 35px;
    position: relative;
  }

  /* #css-container .arrow-bottom-box::after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    border: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin-left: -10px;
    left: 50%;
    border-top: 10px solid #a4a2a2;
    bottom: -10px;
  } */

  #css-container .bounce-up-and-down {
    animation: up-and-down 3s ease-in-out infinite;
    height: 50px;
  }

  @keyframes up-and-down {
    0%,
    100% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 20px);
    }
  }

  #css-container .stick-to-bottom {
    position: sticky;
    bottom: 10px;
    display: inline-flex;
    justify-content: center;
  }

  #css-container strong.colorized {
    color: var(--color-primary);
  }

  #css-container .steps {
    margin-bottom: 32px;
  }

  #css-container .steps .box {
    position: relative;
    margin: 8px 8px 64px 8px;
    padding: 40px 8px 60px 8px;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  }

  #css-container .steps .box.one::before,
  #css-container .steps .box.two::before,
  #css-container .steps .box.three::before {
    position: absolute;
    top: -15px;
    right: 15px;
    font-size: 50px;
    font-weight: bold;
    color: var(--color-primary);
  }

  #css-container .steps .box.one::before {
    content: "۱";
  }

  #css-container .steps .box.two::before {
    content: "۲";
  }

  #css-container .steps .box.three::before {
    content: "۳";
  }

  #css-container .steps .box .logo {
    position: absolute;
    bottom: -50px;
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 100%;
  }

  #css-container .steps .box .logo img {
    width: 60px;
  }

  #css-container .plugins .plugin-box {
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #f9f9f9;
    font-size: 20px;
    margin: 8px 8px 16px 8px;
  }

  #css-container .plugins .plugin-box .logo {
    padding: 16px;
    text-align: center;
  }

  #css-container .plugins .plugin-box .logo img {
    max-width: 100%;
    height: 120px;
  }

  #css-container .plugins .plugin-box .name {
    text-align: center;
    padding-bottom: 16px;
  }

  #css-container .plugins .plugin-box .video-url {
    border-top: 1px solid black;
    text-align: center;
  }

  #css-container .plugins .plugin-box .video-url a {
    position: relative;
    text-decoration: none;
    color: var(--text);
  }

  #css-container .play-button {
    height: 80%;
    margin-right: 8px;
    margin-left: 8px;
  }

  #css-container section.divider {
    text-align: right;
    color: white;
    padding: 3px;
    padding-right: 10px;
    background: var(--color-primary);
    border-style: hidden hidden double hidden;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #css-container section.email input,
  #css-container section.cell-phone input,
  #css-container section.land-phone input,
  #css-container section.nid input,
  #css-container section.postal-code input,
  #css-container section.email input,
  #css-container section.web-site input,
  #css-container section.jalaali-date input,
  #css-container section.iban input {
    direction: ltr;
  }

  #css-container table.report-table {
    border-collapse: separate;
    border: 1px solid var(--report-title);
    border-radius: 13px;
    -moz-border-radius: 13px;
    border-spacing: 0;
    margin: 1.5rem auto;
  }

  #css-container table.report-table td,
  #css-container table.report-table th {
    border-collapse: collapse;
    border-bottom: 1px solid var(--report-title);
    border-left: 1px solid var(--report-title);
    padding: 5px;
  }

  #css-container table.report-table tr:last-child > td {
    border-bottom: none;
  }

  #css-container table.report-table tr:last-child > td:first-child {
    border-bottom-right-radius: 12px;
    -moz-border-bottom-right-radius: 12px;
  }

  #css-container table.report-table tr > th:first-child,
  #css-container table.report-table tr > td:first-child {
    border-right: none;
  }

  #css-container table.report-table tr > th:last-child,
  #css-container table.report-table tr > td:last-child {
    border-left: none;
  }

  #css-container .report-header {
    background: var(--report-header);
  }

  #css-container .report-data {
    font-weight: bold;
  }

  #css-container .report-title {
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    -moz-border-top-left-radius: 12px;
    -moz-border-top-right-radius: 12px;
    background: var(--report-title);
    color: white;
  }

  #css-container .dummy-band {
    height: 75px;
  }

  #css-container .left-aligned {
    text-align: left;
  }

  #css-container .right-aligned {
    text-align: right;
  }

  #css-container .center-aligned {
    text-align: center;
  }

  /* Larger than mobile */
  @media (min-width: 550px) {
    #css-container .on-mobile {
      display: none;
    }

    #css-container .on-desktop {
      display: flex;
    }
  }

  .p-money {
    color: green;
  }

  .n-money {
    color: red;
  }
</style>