<template>
    <div class="main-box">

        <div id="snippet-resource" class="column minimal center-vertically">
            <div class="info">
                <div class="snippet-image-container" :style="{ backgroundColor: item.color }">
                    <div class="snippet-image">
                        <img :src="item.image">
                    </div>
                </div>

                <div>
                    <p class="title">{{ item.title }}</p>
                </div>
            </div>

            <a :href="item.link" target="_blank">
                <button id="github" class="button">لینک github</button>
            </a>
        </div>

        <div class="detail fit-remaining">
            <div>
                <p class="title"> {{ item.summary }} </p>
            </div>
            
            <div>
                <p class="description"> {{ item.description }} </p>
            </div>

            <div class="features-menu">
                <div class="tabs">
                    <div v-if="item.info != null"
                        class="tab"
                        :class="{'tab-selected': target==='info'}"
                        @click.prevent="target='info'">
                            اطلاعات
                    </div>
                    <div v-if="item.guide != null"
                        class="tab" 
                        :class="{'tab-selected': target==='guide'}" 
                        @click.prevent="target='guide'">
                            راهنمای نصب
                    </div>
                </div>
                <div      v-if="target === 'info'"  class="view-panel">{{ item.info }}</div>
                <div v-else-if="target === 'guide'" class="view-panel"><span v-html="guideContent"></span></div>
            </div>

        </div>

    </div>
</template>

<script>
import showdown from "showdown"
import { findSnippet } from './SnippetsData.js'

export default {
    name: 'webpay-snippet-detail',

    props: {
        snippetId: {
            type: String,
            required: true
        },
    },

    data: function() {
        return {
            item: {},
            target: 'info',
            guideContent: '',
        }
    },

    mounted() {
        this.item = findSnippet(this.snippetId)
        if (this.item.guide != null) {
            this.loadGuide(this.item.guide)
        }
    },

    methods: {
        loadGuide(path) {
            fetch(path)
                .then(r => r.text())
                .then(text => {
                    let converter = new showdown.Converter()
                    let html = converter.makeHtml(text)

                    let parser = new DOMParser()
                    let doc = parser.parseFromString(html, 'text/html')
                    let bodyElem = doc.getElementsByTagName('body')

                    this.guideContent = bodyElem.item(0).innerHTML
                })
        },
    },
}
</script>

<style scoped>
/* Mobile first queries */
img {
    width: 90%;
}

.extension-box {
    display: inline-flex;
    margin: 20px 20px;
    align-items: flex-start;
}

.snippet-image-container,
.snippet-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.snippet-image-container {
    width: 180px;
    padding: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.snippet-image {
    width: 100px;
    height: 100px;
    margin: 25px 0;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}

#github {
    text-transform: none;
}

hr {
    width: 100%;
    height: 2px;
    margin: 0;
    border-top: 1px solid gray;
}

.snippet-block {
    display: flex;
    flex-wrap: wrap;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 12px;
}

#snippet-resource .info p.title {
    font-weight: bold;
    margin: 20px;
    color: black;
}

#snippet-resource .info p.description {
    color: var(--greyish-brown);
    font-size: 1em;
    margin: 5px;
}

.detail {
    display: flex;
    flex-direction: column;
    min-width: 70%;
}

.detail p.title {
    color: black;
    font-size: 1.5em;
    font-weight: bold;
    margin: 5px;
}

.detail p.description {
    color: var(--greyish-brown);
    font-size: 1em;
    margin: 5px;
}

.features-menu {
    margin: 20px 0;
}

.view-panel {
    text-align: justify;
}

.back-link {
    color: gray;
    font-size: 0.9em;
    text-align: left;
}

.back-link a:link, a:visited {
    color: gray;
}

#snippet-resource {
    margin: 0 0 20px 20px;
}

/* Larger than mobile */
@media (min-width: 400px) {
    /* img {
        width: 300px;
    } */
}

/* Larger than phablet */
@media (min-width: 550px) {
    /* img {
        width: 300px;
    } */
}

/* Larger than tablet */
@media (min-width: 750px) {
    /* img {
        width: 300px;
    } */
}

/* Larger than desktop */
@media (min-width: 1000px) {
    /* img {
        width: 300px;
    } */
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
    /* img {
        width: 300px;
    } */
}
</style>
