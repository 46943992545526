import {loaderFactory} from "./utils"
import axios from "axios";

export default {
  namespaced: true,
  state: {
    // 'getting', 'posting', 'ok'
    // 'error', 'not_authorized', 'no_profile'
    status: '',
    list: [],
    created: {title: '', api_key: ''},
    withdrawResults: {},
    sendResults: {},
    walletName: ''
  },
  mutations: {
    set(state, merchants) {
      state.status = 'ok'
      state.list = merchants
    },
    setStatus(state, merchantsStatus) {
      state.status = merchantsStatus
    },
    setCreated(state, merchant) {
      state.status = 'ok'
      state.created = merchant
    },
    remove(state, merchant_id) {
      state.list = state.list.filter(m => m.merchant.merchant_id !== merchant_id)
    },
    newKey(state, payload) {
      let m = state.list.find(
        m => m.merchant.merchant_id === payload.merchant_id)
      m.api_key = payload.api_key
      state.created = m
    },
    keepWithdrawResults(state, payload) {
      state.status = 'ok'
      state.withdrawResults = payload
    },
    keepSendResults(state, payload) {
      state.status = 'ok'
      state.sendResults = payload
    },
    keepWalletName(state, payload) {
      state.status = 'ok'
      state.walletName = payload
    }
  },
  getters: {
    isLoading(state) {
      return state.status === 'getting'
    },
    isCreating(state) {
      return state.status === 'posting'
    },
    merchants(state) {
      return state.list
    },
    merchant: (state) => (merchant_id) => {
      return state.list.find(m => m.merchant.merchant_id === merchant_id)
    },
    withdrawResults(state) {
      return state.withdrawResults
    },
    sendResults(state) {
      return state.sendResults
    },
    created(state) {
      return state.created
    },
  },
  actions: {
    load: loaderFactory('/api/owner/get_merchant_list'),
    async create({dispatch, commit, rootGetters}, params) {
      commit('setStatus', 'posting')
      params.key = rootGetters.sessionKey
      await axios.get('/api/owner/create_merchant', {params})
        .catch(error => {
          commit('setStatus', 'error')
          if (error.response) {
            alert(error.response.config.url + " => " + error.response.data)
          } else if (error.request) {
            alert(error.request.config.url + " => no response")
          } else {
            alert(error.message)
          }
        }).then(response => {
          if (response == null || response.data == null)
            return null

          return response.data
        }).then(data => {
          if (data == null) {
            commit('setStatus', '')
          }

          if (data.ok) {
            commit('setCreated', data.result)
            dispatch('load')
            return
          }

          switch (data.error) {
            case 'NOT_AUTHORIZED':
              commit('setStatus', 'not_authorized')
              break
            case 'NO_PROFILE':
              commit('setStatus', 'no_profile')
              break
            default:
              commit('setStatus', 'ok')
              throw data.error
          }
        })
    },
    async update({dispatch, commit, rootGetters}, params) {
      commit('setStatus', 'posting')
      params.key = rootGetters.sessionKey
      await axios.get('/api/owner/update_merchant', {params})
        .catch(error => {
          commit('setStatus', 'error')
          if (error.response) {
            alert(error.response.config.url + " => " + error.response.data)
          } else if (error.request) {
            alert(error.request.config.url + " => no response")
          } else {
            alert(error.message)
          }
        }).then(response => {
          if (response == null || response.data == null)
            return null

          return response.data
        }).then(data => {
          if (data == null) {
            commit('setStatus', '')
            return
          }

          if (data.ok) {
            commit('setStatus', 'ok')
            dispatch('load')
            return 'hi'
          }

          switch (data.error) {
            case 'NOT_AUTHORIZED':
              commit('setStatus', 'not_authorized')
              break
            case 'NO_PROFILE':
              commit('setStatus', 'no_profile')
              break
            default:
              commit('setStatus', 'ok')
              throw data.error
          }
        })
    },
    async remove({commit, rootGetters}, params) {
      commit('setStatus', 'posting')
      params.key = rootGetters.sessionKey
      await axios.get('/api/owner/delete_merchant', {params})
        .catch(error => {
          commit('setStatus', 'error')
          if (error.response) {
            alert(error.response.config.url + " => " + error.response.data)
          } else if (error.request) {
            alert(error.request.config.url + " => no response")
          } else {
            alert(error.message)
          }
        }).then(response => {
          if (response == null || response.data == null)
            return null

          return response.data
        }).then(data => {
          if (data == null) {
            commit('setStatus', '')
            return
          }

          if (data.ok) {
            commit('setStatus', 'ok')
            commit('remove', params.merchant_id)
            return
          }

          switch (data.error) {
            case 'NOT_AUTHORIZED':
              commit('setStatus', 'not_authorized')
              break
            default:
              commit('setStatus', 'ok')
              alert('unknown error for merchant/remove action: ' + data.error)
              break
          }
        })
    },
    async renewKey({commit, rootGetters}, params) {
      commit('setStatus', 'posting')
      params.key = rootGetters.sessionKey
      await axios.get('/api/owner/renew_key', {params})
        .catch(error => {
          commit('setStatus', 'error')
          if (error.response) {
            alert(error.response.config.url + " => " + error.response.data)
          } else if (error.request) {
            alert(error.request.config.url + " => no response")
          } else {
            alert(error.message)
          }
        }).then(response => {
          if (response == null || response.data == null)
            return null

          return response.data
        }).then(data => {
          if (data == null) {
            commit('setStatus', '')
            return
          }

          if (data.ok) {
            commit('setStatus', 'ok')
            commit('newKey', {
              merchant_id: params.merchant_id,
              api_key: data.result.api_key,
            })
            return
          }

          switch (data.error) {
            case 'NOT_AUTHORIZED':
              commit('setStatus', 'not_authorized')
              break
            default:
              commit('setStatus', 'ok')
              alert('unknown error for merchant/renewKey action: ' + data.error)
              break
          }
        })
    },
    async withdraw({commit, rootGetters}, params) {
      commit('setStatus', 'posting')
      params.key = rootGetters.sessionKey
      await axios.get('/api/owner/v2/withdraw', {params})
        .catch(error => {
          commit('setStatus', 'error')
          if (error.response) {
            alert(error.response.config.url + " => " + error.response.data)
          } else if (error.request) {
            alert(error.request.config.url + " => no response")
          } else {
            alert(error.message)
          }
        }).then(response => {
          if (response == null || response.data == null)
            return null

          return response.data
        }).then(data => {
          if (data == null) {
            commit('setStatus', '')
            return
          }

          if (data.ok) {
            commit('setStatus', 'ok')
            commit('keepWithdrawResults', data.result.transfer_results)
            return
          }

          switch (data.error) {
            case 'NOT_AUTHORIZED':
              commit('setStatus', 'not_authorized')
              break
            default:
              commit('setStatus', 'ok')
              alert('unknown error for merchant/withdraw action: ' + data.error)
              break
          }
        })
    },
    async send({commit, rootGetters}, params) {
      commit('setStatus', 'posting')
      params.key = rootGetters.sessionKey
      await axios.get('/api/owner/v2/send', {params})
        .catch(error => {
          commit('setStatus', 'error')
          if (error.response) {
            alert(error.response.config.url + " => " + error.response.data)
          } else if (error.request) {
            alert(error.request.config.url + " => no response")
          } else {
            alert(error.message)
          }
        }).then(response => {
          if (response == null || response.data == null)
            return null

          return response.data
        }).then(data => {
          if (data == null) {
            commit('setStatus', '')
            return
          }

          if (data.ok) {
            commit('setStatus', 'ok')
            commit('keepSendResults', data.result.send_results)
            return
          }

          switch (data.error) {
            case 'NOT_AUTHORIZED':
              commit('setStatus', 'not_authorized')
              break
            default:
              commit('setStatus', 'ok')
              alert('unknown error for merchant/send action: ' + data.error)
              break
          }
        })
    },
  },
  modules: {
    payment: {
      namespaced: true,
      modules: {
        daily: {
          namespaced: true,
          state: {
            data: new Map(),
          },
          mutations: {
            set(state, payload) {
              state.data.set(payload.tag, payload.data)
            },
          },
          getters: {
            get: (state) => (tag) => state.data.get(tag),
          },
          actions: {
            async load({commit, rootGetters}, req) {
              if (req.params == null)
                req.params = {}
              req.params.key = rootGetters.sessionKey
              await axios.get('/api/owner/report_payment_daily',
                {params: req.params})
                .catch(error => {
                  let msg
                  if (error.response) {
                    msg = error.response.config.url + " => " + error.response.data
                  } else if (error.request) {
                    msg = error.request.config.url + " => no response"
                  } else {
                    msg = error.message
                  }
                  console.log(msg)
                })
                .then(response => {
                  if (response == null || response.data == null)
                    return null

                  return response.data
                })
                .then(data => {
                  if (data == null) {
                    return
                  }

                  if (data.ok) {
                    commit('set', {
                      tag: req.tag,
                      data: data.result,
                    })
                    return
                  }

                  switch (data.error) {
                    case 'NOT_AUTHORIZED':
                      commit('setStatus', 'not_authorized', {root: true})
                      break
                    case 'NO_PROFILE':
                      commit('setStatus', 'no_profile', {root: true})
                      break
                    default:
                      console.log(data.error)
                  }
                })
            },
          },
        },
        detail: {
          namespaced: true,
          state: {
            data: new Map(),
          },
          mutations: {
            set(state, payload) {
              state.data.set(payload.tag, payload.data)
            },
          },
          getters: {
            get: (state) => (tag) => state.data.get(tag),
          },
          actions: {
            async load({commit, rootGetters}, req) {
              if (req.params == null)
                req.params = {}
              req.params.key = rootGetters.sessionKey
              await axios.get('/api/owner/report_payment_detail',
                {params: req.params})
                .catch(error => {
                  let msg
                  if (error.response) {
                    msg = error.response.config.url + " => " + error.response.data
                  } else if (error.request) {
                    msg = error.request.config.url + " => no response"
                  } else {
                    msg = error.message
                  }
                  console.log(msg)
                })
                .then(response => {
                  if (response == null || response.data == null)
                    return null

                  return response.data
                })
                .then(data => {
                  if (data == null) {
                    return
                  }

                  if (data.ok) {
                    commit('set', {
                      tag: req.tag,
                      data: data.result,
                    })
                    return
                  }

                  switch (data.error) {
                    case 'NOT_AUTHORIZED':
                      commit('setStatus', 'not_authorized', {root: true})
                      break
                    case 'NO_PROFILE':
                      commit('setStatus', 'no_profile', {root: true})
                      break
                    default:
                      console.log(data.error)
                  }
                })
            },
          },
        },
        summary: {
          namespaced: true,
          state: {
            data: new Map(),
          },
          mutations: {
            set(state, payload) {
              state.data.set(payload.tag, payload.data)
            },
          },
          getters: {
            get: (state) => (tag) => state.data.get(tag),
          },
          actions: {
            async load({commit, rootGetters}, req) {
              if (req.params == null)
                req.params = {}
              req.params.key = rootGetters.sessionKey
              await axios.get('/api/owner/report_payment_summary',
                {params: req.params})
                .catch(error => {
                  let msg
                  if (error.response) {
                    msg = error.response.config.url + " => " + error.response.data
                  } else if (error.request) {
                    msg = error.request.config.url + " => no response"
                  } else {
                    msg = error.message
                  }
                  console.log(msg)
                })
                .then(response => {
                  if (response == null || response.data == null)
                    return null

                  return response.data
                })
                .then(data => {
                  if (data == null) {
                    return
                  }

                  if (data.ok) {
                    commit('set', {
                      tag: req.tag,
                      data: data.result,
                    })
                    return
                  }

                  switch (data.error) {
                    case 'NOT_AUTHORIZED':
                      commit('setStatus', 'not_authorized', {root: true})
                      break
                    case 'NO_PROFILE':
                      commit('setStatus', 'no_profile', {root: true})
                      break
                    default:
                      console.log(data.error)
                  }
                })
            },
          },
        },
      },
    },
    transferSummary: {
      namespaced: true,
      state: {
        // 'getting', 'ok'
        // 'error', 'not_authorized', 'no_profile'
        status: '',
        data: new Map(),
      },
      mutations: {
        add(state, payload) {
          state.status = 'ok'
          state.data.set(payload.id, payload.data)
        },
        setStatus(state, status) {
          state.status = status
        },
      },
      getters: {
        get: (state) => (id) => state.data.get(id),
      },
      actions: {
        async load({commit, rootGetters}, params) {
          params.key = rootGetters.sessionKey
          commit('setStatus', 'getting')
          await axios.get('/api/owner/report_transfer_summary', {params})
            .catch(error => {
              commit('setStatus', 'error')
              if (error.response) {
                alert(error.response.config.url + " => " + error.response.data)
              } else if (error.request) {
                alert(error.request.config.url + " => no response")
              } else {
                alert(error.message)
              }
            }).then(response => {
              if (response == null || response.data == null)
                return null

              return response.data
            }).then(data => {

              if (data == null) {
                commit('setStatus', '')
              }

              if (data.ok) {
                commit('add', {
                  id: params.merchant_id,
                  data: data.result.row,
                })
                return
              }

              switch (data.error) {
                case 'NOT_AUTHORIZED':
                  commit('setStatus', 'not_authorized')
                  break
                case 'NO_PROFILE':
                  commit('setStatus', 'no_profile')
                  break
                default:
                  commit('setStatus', 'error')
                  console.log('transferSummary/load unknown error:', data.error)
                  break
              }
            })
        },
        async daily({commit, rootGetters}, req) {
          if (req.params == null)
            req.params = {}
          req.params.key = rootGetters.sessionKey
          commit('setStatus', 'getting')
          await axios.get('/api/owner/report_transfer_daily', {
            params: req.params,
          })
            .catch(error => {
              commit('setStatus', 'error')
              if (error.response) {
                alert(error.response.config.url + " => " + error.response.data)
              } else if (error.request) {
                alert(error.request.config.url + " => no response")
              } else {
                alert(error.message)
              }
            }).then(response => {
              if (response == null || response.data == null)
                return null

              return response.data
            }).then(data => {
              if (data == null) {
                commit('setStatus', '')
              }

              if (data.ok) {
                commit('add', {
                  id: req.tag,
                  data: data.result,
                })
                return
              }

              console.log('transfer daily:', data.error)
              switch (data.error) {
                case 'NOT_AUTHORIZED':
                  commit('setStatus', 'not_authorized')
                  break
                default:
                  commit('setStatus', 'error')
                  alert('unknown error for transferDaily/load action: ' + data.error)
                  break
              }
            })
        },
      },
    },

    walletReport: {
      namespaced: true,
      state: {
        // 'getting', 'ok'
        // 'error', 'not_authorized', 'no_profile'
        status: '',
        data: new Map(),
      },
      mutations: {
        add(state, payload) {
          state.status = 'ok'
          state.data.set(payload.id, payload.data)
        },
        setStatus(state, status) {
          state.status = status
        },
      },
      getters: {
        get: (state) => (id) => state.data.get(id),
      },
      actions: {
        async load({commit, rootGetters}, req) {
          var params = req.params
          params.key = rootGetters.sessionKey
          commit('setStatus', 'getting')
          await axios.get('/api/owner/report_wallet_summary', {params})
            .catch(error => {
              commit('setStatus', 'error')
              if (error.response) {
                alert(error.response.config.url + " => " + error.response.data)
              } else if (error.request) {
                alert(error.request.config.url + " => no response")
              } else {
                alert(error.message)
              }
            }).then(response => {
              if (response == null || response.data == null)
                return null

              return response.data
            }).then(data => {
              if (data == null) {
                commit('setStatus', '')
              }

              if (data.ok) {
                commit('add', {
                  id: params.merchant_id,
                  data: data.result.data,
                })
                return
              }

              switch (data.error) {
                case 'NOT_AUTHORIZED':
                  commit('setStatus', 'not_authorized')
                  break
                case 'NO_PROFILE':
                  commit('setStatus', 'no_profile')
                  break
                default:
                  commit('setStatus', 'error')
                  console.log('walletSummary/load unknown error:', data.error)
                  break
              }
            })
        },
      },
    },
  }
}