import Vue from 'vue'
import Vuex from 'vuex'
import profile from './store/profile'
import merchant from "./store/merchant"
import service from "./store/service"

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    sessionKey: localStorage.getItem('session-key') || '',
    userProfile: null,
    loadingProfile: false,
    merchants: [],
    panelLoading: [],
    currentMerchantId: localStorage.getItem('current-merchant-id') || '',
    referralCode: localStorage.getItem('referral-code') || '',

    sampleStoreKey: localStorage.getItem('sample-store-key'),
    sampleStoreLastReference: localStorage.getItem('sample-store-last-reference'),

    status: '',
  },
  mutations: {
    setSessionKey(state, key) {
      localStorage.setItem('session-key', key)
      state.sessionKey = key
    },
    removeSessionKey(state) {
      localStorage.removeItem('session-key')
      state.sessionKey = ''
    },
    logout(state) {
      localStorage.removeItem('session-key')
      localStorage.removeItem('current-merchant-id')
      state.sessionKey = ''
      state.userProfile = {}
      state.merchants = []

      state.sampleStoreKey = ''
      state.sampleStoreLastReference = 0
    },
    setUserProfile(state, profile) {
      state.userProfile = profile
    },
    setMerchants(state, merchants) {
      state.merchants = merchants
    },
    setCurrentMerchantId(state, merchantId) {
      localStorage.setItem('current-merchant-id', merchantId)
      state.currentMerchantId = merchantId
    },
    addLoading(state, tag) {
      let i = state.panelLoading.indexOf(tag)
      if (i === -1) {
        state.panelLoading.push(tag)
      }
    },
    removeLoading(state, tag) {
      let i = state.panelLoading.indexOf(tag)
      if (i !== -1) {
        state.panelLoading.splice(i, 1)
      }
    },
    setReferralCode(state, referralCode) {
      localStorage.setItem('referral-code', referralCode)
      state.referralCode = referralCode
    },
    removeReferralCode(state) {
      localStorage.removeItem('referral-code')
      state.referralCode = ''
    },
    setSampleStoreKey(state, key) {
      localStorage.setItem('sample-store-key', key)
      state.sampleStoreKey = key
    },
    setSampleStoreLastReference(state, ref) {
      localStorage.setItem('sample-store-last-reference', ref)
      state.sampleStoreLastReference = ref
    },

    setStatus(state, payload) {
      state.status = payload
    },

    // UI mutations
    showCopiedSnackbar() {}
  },
  getters: {
    sessionKey: state => {
      return state.sessionKey
    },
    loggedIn: state => {
      return state.sessionKey !== ''
    },
    hasUserProfile: state => {
      return state.userProfile != null
    },
    userFirstName: state => {
      if (state.userProfile != null)
        return state.userProfile.first_name
      return ''
    },
    userName: state => {
      if (state.userProfile != null)
        return state.userProfile.first_name + ' ' + state.userProfile.last_name
      return ''
    },
    userEmail: state => {
      if (state.userProfile != null)
        return state.userProfile.email
      return ''
    },
    showLoading: state => {
      return state.panelLoading.length !== 0
    },
    hasReferralCode: state => {
      return state.referralCode != null && state.referralCode !== ''
    }
  },
  actions: {
    async logout({commit}) {
      commit('removeSessionKey')
      commit('profile/remove')
    },
  },
  modules: {
    profile,
    merchant,
    service,
  }
})
