<template>
    <div class="login">
        <webpay-header-login/>

        <div class="container">
            <div class="row">
                <form
                    class="signin-form"
                    @submit.prevent="submit">

                    <section v-show="gettingPhone" class="center-horizontally">
                        <cell-phone
                            label = "شماره همراه خود را وارد کنید:"
                            name = "شماره همراه"
                            :is-forced=true
                            ref="phoneInput"
                            v-model="phone"
                            >
                        </cell-phone>

                        <button
                            :class="{loading: submitting}"
                            @click.prevent="submit"
                            @keyup.enter="submit">
                            تایید شماره همراه
                        </button>
                    </section>

                    <section v-show="gettingCode" class="center-horizontally">
                        <label>کد فعال‌سازی به شماره {{ phoneWithZero | toPersianDigits }} فرستاده شد.</label>
                        <p/>
                        <general-text
                            id="code"
                            label="کد فرستاده شده را وارد کنید:"
                            align="center"
                            name = "کد"
                            :is-forced=true
                            ref="codeInput"
                            v-model="code">
                        </general-text>

                        <button
                            :class="{loading: submitting}"
                            @click.prevent="submit"
                            @keyup.enter="submit">
                            تایید کد
                        </button>
                    </section>

                    <hr>

                    <sub>استفاده از باهمتا به معنای پذیرفتن <a target="_blank" href="https://bahamta.com/terms">قوانین کاربری</a> آن است.</sub>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import WebpayHeaderLogin from '../headers/WebpayHeaderLogin.vue'
import CellPhone from '../base/CellPhone.vue'
import GeneralText from '../base/GeneralText.vue'

export default {
    name: 'webpay-login',
    components: {
        WebpayHeaderLogin,
        CellPhone,
        GeneralText,
    },

    data: function() { return {
        submitting: false,
        phone: '',
        code: '',
        token: '',
        referralCode: '',
    }},

    computed: {
        gettingPhone() {
            return this.token === ''
        },

        gettingCode() {
            return this.token !== ''
        },

        phoneWithZero() {
            return this.toNationalFormat(this.phone)
        },
    },

    mounted() {
        this.$refs.phoneInput.focus()
        this.referralCode = this.$route.params.referral
    },

    methods: {
        submit() {
            if (this.gettingPhone) {
                this.$refs.phoneInput.checkValue()
                if (this.$refs.phoneInput.hasError()) {
                    return
                }

                let error = ''
                this.phone = this.toEnglishDigits(this.$refs.phoneInput.getInternationalFormat())
                this.submitting = true
                this.sendPhone(this.phone, response => {
                    if (!response) {
                        error = 'پاسخی از سرور دریافت نشد'
                    } else if (!response.ok) {
                        switch (response.error) {
                            case 'TOO_MANY_KNOCKS':
                                // TODO: specify how much to wait
                                error = 'مدتی صبر کنید و دوباره تلاش کنید'
                                break
                            default:
                                error = response.error
                        }
                    } else {
                        this.token = response.result.token
                        error = ''
                    }
                    
                    this.$refs.phoneInput.setError(error)

                }, () => {
                    this.submitting = false
                    this.$refs.codeInput.focus()
                })

            } else if (this.gettingCode) {
                let code = this.toEnglishDigits(this.$refs.codeInput.toString())
                let [ok, msg] = this.checkCode(code)
                if (!ok) {
                    this.$refs.codeInput.setError(msg)
                    return
                }

                let error = ''
                this.submitting = true
                this.sendCode(this.token, code, response => {
                    if (!response) {
                        error = 'پاسخی از سرور دریافت نشد'
                    } else if (!response.ok) {
                        switch (response.error) {
                            case 'MISMATCHED_DATA':
                                error = 'کد وارد شده صحیح نیست'
                                break
                            case 'ALREADY_USED':
                                error = 'این کد پیش از این استفاده شده است. برای امنیت بیشتر، لطفاً دوباره کد دریافت کنید.'
                                break
                            case 'TOO_MANY_FAILED_LOGIN':
                                error = 'در استفاده از این کد بیش از حد مجاز خطا کردید. لطفاً مدتی صبر کنید و دوباره تلاش کنید.'
                                break
                            case 'EXPIRED_KNOCK':
                                error = 'به خاطر گذشت زمانی طولانی، این کد اعتبار خود را از دست داده است. لطفاً دوباره کد دریافت کنید.'
                                break
                            default:
                                error = response.error
                        }
                    } else {
                        error = ''
                        this.$store.commit('setSessionKey', response.result.key)
                        this.$router.push({path: '/panel', query: { referral_code: this.referralCode}})
                    }

                    this.$refs.codeInput.setError(error)
                }, () => { this.submitting = false })
            }
        },

        sendPhone(phone, callback, done) {
            axios.get('/api/owner/knock', {
                params: {
                    mobile: phone
                }
            }).then(response => {
                if (response == null || response.data == null) {
                    callback(null)
                    return
                }

                callback(response.data)
            }).catch(error => {
                if (error.response) {
                    alert(error.response.config.url + " => " + error.response.data)
                } else if (error.request) {
                    alert(error.request.config.url + " => no response")
                } else {
                    alert(error.message)
                }
            }).finally(() => {
                done()
            })
        },

        checkCode(code) {
            if (code.length === 0) {
                return [false, '']
            } if (code.length !== 6) {
                return [false, 'کد وارد شده باید ۶ رقمی باشد']
            }
            return [true, '']
        },

        sendCode(token, code, callback, done) {
            axios.get('/api/owner/login', {
                params: {
                    token,
                    code,
                    label: navigator.userAgent,
                }
            }).then(response => {
                if (response == null || response.data == null) {
                    callback(null)
                    return
                }

                callback(response.data)
            }).catch(error => {
                if (error.response) {
                    alert(error.response.config.url + " => " + error.response.data)
                } else if (error.request) {
                    alert(error.request.config.url + " => no response")
                } else {
                    alert(error.message)
                }
            }).finally(() => {
                done()
            })
        },
    },
    watch: {
        phone(value) {
            let phone = this.toEnglishDigits(this.onlyDigits(value))
            if (phone.length === 0 || phone[0] === '0') {
                this.phone = ''
                return
            }
            this.phone = this.toPersianDigits(phone)
        },
        code(value) {
            this.code = this.toPersianDigits(this.onlyDigits(value))
        }
    }
}
</script>

<style scoped>

.login header.header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    box-shadow: none; 
    background-color: white;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
}

.login header.header .bahamta-logo {
    margin: 8px;
    height: 35px;
}

.login header.header .mobile-menu {
    height: 32px;
    margin: 8px;
}

/* .login .content {
    margin: 75px 16px;
    min-height: calc(100vh - 128px);
}

.login .content.loading {
    display: flex;
    justify-content: center;
    align-items: center;
} */
/* 
.login .content.loading .profile-form,
.login .content.loading .merchants  {
    display: none;
}

.login .content .profile-form,
.login .content .new-merchant {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .content .merchants {
    height: 100%;
}

.login .content .merchants .no-merchants {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .content .merchants .list .item {
    border: 1px solid var(--color-primary);
    padding: 16px;
    margin: 16px;
    border-radius: 10px;
}

.login .content .merchants .list .item h1 {
    margin: 0 0 8px 0;
    font-size: 24px;
} */

@media (min-width: 750px) {
    .panel .header {
        display: none;
    }

    .panel .content {
        margin-right: 30%;
        padding-right: 5%;
        padding-left: 5%;
    }

    .panel .side-menu {
        width: 30%;
        right: 0;
    }
}

@media (min-width: 1000px) {
    .panel .content {
        margin-right: 20%;
        padding-right: 10%;
        padding-left: 10%;
    }

    .panel .side-menu {
        width: 20%;
        right: 0;
    }
}

.row {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin-form {
    height: max-content;
    width: 100%;
    max-width: 550px;
    text-align: center;
}

.signin-form label {
    text-align: right;
}

.signin-form input.phone-number {
    direction: ltr;
    box-sizing: border-box;
    position: relative;
    width: calc(100% - 30px);
    margin-left: 30px;
}

.signin-form section {
    width: 100%;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
}

.input-with-prefix .prefix {
    color: var(--text-scondary)
}

.signin-form .input.field.general-text {
    text-align: center;
    direction: ltr;
}

</style>