<template>
  <div class="plugins">
    <div class="description">{{ this.description }} زیر توسط تیم باهمتا توسعه داده شده و یا استفاده از آنها مورد تأیید باهمتا است.</div>

    <div class="plugins-list">
      <router-link
        :to="{ name: itemRoute, params: { id: item.id } }"
        v-for="item in list"
        :key="item.id"
        class="plugin"
      >
        <div
          class="plugin-image-container"
          :style="{ backgroundColor: item.color }"
        >
          <div class="plugin-image">
            <img :src="item.image" />
          </div>
        </div>

        <div class="plugin-title-container">
          <div class="plugin-title">
            {{ item.title }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { plugins } from "../plugin/PluginsData";
import { snippets } from "../plugin/SnippetsData";

export default {
  name: "clients-list",

  props: {
    section: String,
  },

  mounted() {
    this.$emit("section", this.section);
  },

  watch: {
    $route() {
      this.$emit("section", this.section);
    },
  },

  computed: {
    description() {
      if (this.section === "plugins")
        return "افزونه‌های";

      if (this.section === "snippets")
        return "کدهای";

      return "";
    },

    list() {
      if (this.section === "plugins") return plugins;

      if (this.section === "snippets") return snippets;

      return [];
    },

    itemRoute() {
      if (this.section === "plugins") return "plugin-detail";

      if (this.section === "snippets") return "snippet-detail";

      return "";
    },
  },
};
</script>

<style scoped>
/* Mobile first queries */
.description {
  text-align: center;
  margin: 80px 0;
}

.plugins-list,
.plugin,
.plugin-image-container .plugin-image,
.plugin-image-container,
.plugin-title-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.plugins-list {
  justify-content: start;
}

.plugin {
  flex-basis: 100%;
  max-width: 100%;
  margin: 2%;
  flex-grow: 1;
  /* border: 1px solid black; */
  border-radius: 12px;

  flex-direction: column;
  align-items: stretch;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.plugin-image-container {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.plugin-image-container .plugin-image {
  width: 150px;
  height: 150px;
  /* margin: 15% 0; */
  margin: 30px 0;
  /* border: 1px solid black; */
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}

.plugin-image img {
  width: 70%;
}

.plugin-title-container {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.plugin-title {
  font-weight: bold;
  margin: 20px;
  color: black;
}

/* Larger than mobile */
@media (min-width: 400px) {
  .plugin {
    flex-basis: 46%;
    max-width: 46%;
  }
}

/* Larger than phablet */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .plugin {
    flex-basis: 29.333%;
    max-width: 29.333%;
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}
</style>
