import Vue from "vue";
import Router from "vue-router";

import CssContainer from "../components/base/CssContainer";
import WebpayLandingMain from "../components/landing/WebpayLandingMain.vue";
import WebpayLandingAffiliation from "../components/landing/WebpayLandingAffiliation";
import WebpayLogin from "../components/login/WebpayLogin.vue";

import WebpayDocApi from "../components/docs/WebpayDocApi.vue";
import WebpayDocWalletApi from "../components/docs/WebpayDocWalletApi.vue";
import WebpayDocFaq from "../components/docs/WebpayDocFaq.vue";

const newPanel = () =>
  import(/* webpackChunkName: "panel" */ "../components/panel/Panel");
const newPanelMerchants = () =>
  import(/* webpackChunkName: "panel" */ "../components/panel/PanelMerchants");
const newPanelProfile = () =>
  import(/* webpackChunkName: "panel" */ "../components/panel/PanelProfile");
const newPanelMerchantDetail = () =>
  import(
    /* webpackChunkName: "panel" */ "../components/panel/PanelMerchantDetail"
  );
const newMerchantPaymentsDetails = () =>
  import(
    /* webpackChunkName: "panel" */ "../components/panel/reports/MerchantPaymentsDetails"
  );
const merchantWithdrawsReport = () =>
  import(
    /* webpackChunkName: "panel" */ "../components/panel/reports/MerchantWithdrawsReport"
  );
const newPanelSettlement = () =>
  import(
    /* webpackChunkName: "panel" */ "../components/panel/reports/TransferDaily"
  );
const newAffiliation = () =>
  import(
    /* webpackChunkName: "panel" */ "../components/panel/reports/Affiliation"
  );
const PanelSupport = () =>
  import(/* webpackChunkName: "panel" */ "../components/panel/PanelSupport");

import clients from "../components/clients/clients.vue";
import clientsList from "../components/clients/clients-list.vue";
import pluginDetails from "../components/plugin/WebpayPluginDetail.vue";
import snippetDetails from "../components/plugin/WebpaySnippetDetail.vue";

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: CssContainer,
    children: [
      { path: "", name: "home", component: WebpayLandingMain },
      {
        path: "affiliation",
        name: "affiliation_details",
        component: WebpayLandingAffiliation,
      },
      { path: "login", name: "login", component: WebpayLogin },
      { path: "doc/api", component: WebpayDocApi },
      { path: "doc/wallet_api", component: WebpayDocWalletApi },
      { path: "doc/faq", component: WebpayDocFaq },
      {
        path: "clients",
        component: clients,
        children: [
          {
            path: "plugins",
            name: "plugins",
            props: { section: "plugins" },
            component: clientsList,
          },
          {
            path: "plugins/:id",
            name: "plugin-detail",
            component: pluginDetails,
            props: (route) => ({ pluginId: route.params.id }),
          },
          {
            path: "snippets",
            name: "snippets",
            props: { section: "snippets" },
            component: clientsList,
          },
          {
            path: "snippets/:id",
            name: "snippet-detail",
            component: snippetDetails,
            props: (route) => ({ snippetId: route.params.id }),
          },
        ],
      },
    ],
  },
  // New pages based on Vuetify framework.
  {
    path: "/panel",
    component: newPanel,
    children: [
      { path: "", name: "panel_merchants", component: newPanelMerchants },
      { path: "profile", name: "profile", component: newPanelProfile },
      {
        path: "settlements",
        name: "settlement",
        component: newPanelSettlement,
      },
      {
        path: "merchants/:id",
        name: "merchant_detail",
        component: newPanelMerchantDetail,
      },
      {
        path: "merchants/:id/reports/payments",
        name: "merchant_payment_report",
        component: newMerchantPaymentsDetails,
      },
      {
        path: "merchants/:id/reports/wallet_tnxs",
        name: "merchant_wallet_tnxs_report",
        component: merchantWithdrawsReport,
      },
      { path: "affiliation", name: "affiliation", component: newAffiliation },
      { path: "support", name: "support", component: PanelSupport },
    ],
  },
];

export default new Router({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});
