import Vue from 'vue'

let persianDigits = "۰۱۲۳۴۵۶۷۸۹"
let englishDigits = {
  '۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4',
  '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9',
  '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
  '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
}

const mobileRegex = /^(98|0)9[0-9]{9}$/

let persianRegex = /^(?:[\u0600-\u06FF]*[\u200c ]?)+$/u
Vue.mixin({
  methods: {
    toPersianDigits: function(number) {
      return this.toEnglishDigits(('' + (number+'' || ''))).replace(/[0-9]/g, function (x) { return persianDigits[x] })
    },
    toEnglishDigits: function(number) {
      return ('' + (number+'' || '')).replace(/[۰-۹٠-٩]/g, function (x) { return englishDigits[x] })
    },
    onlyDigits: function(message) {
      return ('' + (message+'' || '')).replace(/[^0-9٠-٩۰-۹]/g, '')
    },
    onlyDigitsAndDash: function (message) {
      return ('' + (message+'' || '')).replace(/[^0-9٠-٩۰-۹-]/g, '')
    },
    onlyPersianArabic(name) {
      return persianRegex.test(name)
    },
    toNationalFormat(mobile) {
      mobile = this.toEnglishDigits(mobile)
      if (!mobileRegex.test(mobile)) {
        return ""
      }
      return mobile.replace(/^98/, "0")
    },
    // Terminal Function Helpers
    terminalState(terminals) {
      if (terminals == null || terminals.length === 0)
        return 'no-terminal'

      const t = terminals[0]
      return t.state
    },
  }
})

Vue.filter('toPersianDigits', function (value) {
  return ('' + (value || '')).replace(/[0-9]/g, function (x) { return persianDigits[x] })
})
