<template>
    <header class="header">

        <div class="container on-mobile">
            <div style="display:flex;justify-content:space-between;width:100%;">
                <router-link to="/">
                    <img
                        class="bahamta-logo"
                        src="/img/bahamta/logo.svg?v=2">
                </router-link>
                <img
                    class="mobile-menu u-pull-left"
                    src="/img/menu.svg?v=1"
                    @click="openMobileSideNav">
            </div>
        </div>

        <div class="container on-desktop">
            <router-link to="/">
                <img
                    class="icon bahamta-logo"
                    src="/img/bahamta/logo.svg?v=2">
            </router-link>
            
            <div
                class="flex-fill menu link-hover-animation">
                <router-link
                    class="animated"
                    to="/doc/api">
                    {{ docMenuLabel }}
                </router-link>

               <!-- <router-link
                   class="animated"
                   to="/doc/wallet_api">
                   {{ walletDocMenuLabel }}
               </router-link> -->

                <router-link
                    class="animated"
                    to="/doc/faq">
                    {{ faqMenuLabel }}
                </router-link>

                <router-link :to="{name: 'plugins'}"
                    class="animated">
                    {{ pluginMenuLabel }}
                </router-link>

                <router-link to="/affiliation"
                    class="animated">
                    {{ affiliationMenuLabel }}
                </router-link>

                <!-- <router-link to="/sample-store"
                    class="animated">
                    {{ sampleStoreMenuLabel }}
                </router-link> -->

            </div>

            <div class="menu">
                <router-link v-if="loggedIn"
                    to="/panel">
                    {{ panelMenuLabel }}
                </router-link>
                <router-link v-else
                    to="/login">
                    {{ panelMenuLabel }}
                </router-link>
            </div>
        </div>

        <mobile-side-nav :isOpen="isSideNavOpen"
            @mobile-side-nav-close="closeMobileSideNav">
            <router-link v-if="loggedIn"
                class="mobile-nav-link"
                to="/panel">
                {{ panelMenuLabel }}
            </router-link>
            <router-link v-else
                class="mobile-nav-link"
                to="/login">
                {{ panelMenuLabel }}
            </router-link>

            <router-link
                class="mobile-nav-link"
                to="/doc/api">
                {{ docMenuLabel }}
            </router-link>

            <!-- <router-link
                class="animated"
                to="/doc/wallet_api">
                {{ walletDocMenuLabel }}
            </router-link> -->
            
            <router-link
                class="mobile-nav-link"
                to="/affiliation">
                {{ affiliationMenuLabel }}
            </router-link>

            <router-link
                class="mobile-nav-link"
                to="/doc/faq">
                {{ faqMenuLabel }}
            </router-link>
            
            <router-link :to="{name: 'plugins'}"
                class="mobile-nav-link">
                {{ pluginMenuLabel }}
            </router-link>
            
            <!-- <router-link to="/affiliation"
                class="mobile-nav-link">
                {{ affiliationMenuLabel }}
            </router-link> -->

            <!-- <router-link to="/sample-store"
                class="mobile-nav-link">
                {{ sampleStoreMenuLabel }}
            </router-link> -->
            
        </mobile-side-nav>
    </header>
</template>

<script>
import MobileSideNav from '../base/MobileSideNav.vue'

const PANEL_MENU_LABEL = 'ورود به پنل'
const DOC_MENU_LABEL = 'مستندات'
const WALLET_DOC_MENU_LABEL = 'مستندات کیف پول'
const FAQ_MENU_LABEL = 'پرسش‌های متداول'
const PLUGIN_MENU_LABEL = 'افزونه‌ها'
// const AFFILIATION_MENU_LABEL = 'کسب درآمد'
const SAMPLE_STORE_MENU_LABEL = 'یک فروشگاه آزمایشی'

export default {
    name: "webpay-header-landing",
    // props: {
    //     loggedIn: {
    //         type: Boolean,
    //         required: true,
    //     }
    // },

    computed: {
        loggedIn: function() {
            return this.$store.getters.loggedIn
        }
    },

    data: function() {
        return {
            isSideNavOpen: false,

            panelMenuLabel: PANEL_MENU_LABEL,
            docMenuLabel: DOC_MENU_LABEL,
            walletDocMenuLabel: WALLET_DOC_MENU_LABEL,
            faqMenuLabel: FAQ_MENU_LABEL,
            pluginMenuLabel: PLUGIN_MENU_LABEL,
            // affiliationMenuLabel: AFFILIATION_MENU_LABEL,
            sampleStoreMenuLabel: SAMPLE_STORE_MENU_LABEL,
        }
    },

    components: {
        MobileSideNav
    },

    methods: {
        openMobileSideNav() {
            this.isSideNavOpen = true
        },
        closeMobileSideNav() {
            this.isSideNavOpen = false
        },
        scrollFix(hashbang) {
            location.hash = hashbang;
        }
    },

    mounted() {
        // From testing, without a brief timeout, it won't work.
        setTimeout(() => this.scrollFix(this.$route.hash), 25);
    }
}
</script>

<style scoped>

.header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
}

.header .container.on-mobile {
    height: 55px;
}

.header .container.on-desktop {
    height: 75px;
}

.header .container .bahamta-logo {
    margin: 8px 8px 8px 16px;
}

.header .container.on-mobile .bahamta-logo {
    height: 39px;
}

.header .container.on-desktop .bahamta-logo {
    height: 59px;
}

.header .container.on-mobile .mobile-menu {
    height: 31px;
    margin: 12px 0;
}

.header .on-desktop .menu {
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

.header .on-desktop .menu > * {
    padding: 0 16px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-decoration: none;
}

.header .on-desktop .menu .active {
    background-color: var(--color-primary);
    color: var(--text-on-primary-color);
}
</style>