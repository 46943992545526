<template>
    <div>
        <webpay-header-landing 
            :loggedIn="loggedIn"
        />

        <main class="container">
            <div class="dummy-band">
            </div>
            
            <webpay-pages-affiliation></webpay-pages-affiliation>
        </main>

        <webpay-footer-landing />
    </div>
</template>

<script>
import WebpayHeaderLanding from "../headers/WebpayHeaderLanding.vue"
import WebpayFooterLanding from "../footers/WebpayFooterLanding.vue"
import WebpayPagesAffiliation from "../pages/WebpayPagesAffiliation.vue"

export default {
    name: "webpay-landing-affiliation",
    components: {
        WebpayHeaderLanding,
        WebpayPagesAffiliation,
        WebpayFooterLanding,
    },
    computed: {
        loggedIn: function() {
            return this.$store.getters.loggedIn
        }
    },
}
</script>