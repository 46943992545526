<template>
    <div>
        <div class="row intro">
            <div class="five columns">
                <p class="title">
                    کسب درآمد از وب‌پی(webpay)
                </p>

                <p class="description">
                    یکی از روش‌های ساده کسب درآمد در اینترنت، روش همکاری در فروش است. این روش برای هر دو طرف همکاری مزیت ایجاد می‌کند و کاملا یک رابطه برد-برد است.
                    در این روش شما می‌توانید با عضویت در سرویس و پیشنهاد آن به دیگران (از طریق لینک دعوت و یا شماره موبایل)،
                    منفعت داشته باشید. هیچ محدودیتی برای تعداد دعوت شده‌ها وجود ندارد.
                </p>
            </div>
            <div class="seven columns v-align-top">
                <div class="affiliate-banner-image">
                    <img src="/img/affiliate/banner.webp?v=1"/>
                </div>
            </div>
        </div>

        <div class="row intro v-align-center">
            <div class="twelve columns">
                <p class="title">
                    چگونه به عنوان معرف کسب درآمد کنیم؟
                </p>

                <p class="description">
                    درگاه پرداخت اینترنتی «وب‌پی» این امکان را برای شما فراهم می‌کند تا به عنوان «معرف» و بدون صرف زمان و انرژی در این سرویس درآمد کسب کنید.
                    در صورتی که این سرویس را به کاربر دیگری پیشنهاد دهید، به مدت یک سال، درصد مشخصی از کارمزد تراکنش‌‌های آن کاربر به شما تعلق می‌گیرد.
                    تاریخ شروع این دوره یک ساله، از تاریخ اولین تراکنش موفق آن کاربر محاسبه می‌شود. 
                </p>
            </div>
        </div>

        <div class="row intro v-align-top">

            <div class="four columns">

                <div class="affiliate-column-image center-vertically center-horizontally">
                    <img src="/img/affiliate/02.webp?v=1"/>
                </div>

                <p class="title reduced-size">
                    نحوه شروع کار چگونه است؟
                </p>

                <p class="description">
                    کسب درآمد زمانی آغاز می‌شود که مشتری جدید درگاه پرداخت وب‌پی، از طریق لینک «دعوت از دیگران» موجود در حساب کاربری شما، عضو وب‌پی شود
                    و یا در زمان تکمیل اطلاعات کاربری، شماره موبایل شما را به عنوان «معرف» ثبت کند.
                    هیچ محدودیتی برای تعداد افرادی که از طرف شما دعوت می‌شوند وجود ندارد.
                </p>
            </div>

            <div class="four columns">

                <div class="affiliate-column-image center-vertically center-horizontally">
                    <img src="/img/affiliate/01.webp?v=1"/>
                </div>

                <p class="title reduced-size">
                    میزان سود چقدر خواهد بود؟
                </p>

                <p class="description">

                    میزان درآمدی که شما از درگاه پرداخت وب‌پی خواهید داشت رابطه مستقیمی با تعداد و مبلغ تراکنش‌های صاحب درگاه اینترنتی در یک سال اول فعالیتش دارد.
                    میزان کارمزدی که برای «معرف» در نظر گرفته شده است ۲۰ درصد مبلغ کارمزد پرداخت شده از هر تراکنش است که در سال اول انجام می‌شود.
                    به عنوان مثال: برای تراکنشی با مبلغ ۵۰۰ هزار تومان، مبلغ ۵ هزار تومان کارمزد دریافت می‌شود که مبلغ ۱۰۰۰ تومان از آن به «معرف» پرداخت می‌شود.
                </p>
            </div>

            <div class="four columns">
                <div class="affiliate-column-image center-vertically center-horizontally">
                    <img class="affiliate-filling-image" src="/img/affiliate/03.webp?v=1"/>
                </div>

                <p class="title reduced-size">
                    تسویه چگونه انجام می‌شود؟
                </p>

                <p class="description">
                    در ازای هر تراکنشی که مشتریان معرفی شده از سمت شما ثبت می‌کنند، کارمزد شما محاسبه می‌شود. این اطلاعات از طریق پنل موجود در سایت وب‌پی نیز قابل مشاهده است.
                    مبالغ درآمد حاصل از معرفی درگاه پرداخت وب‌پی ذخیره شده و هرگاه به مبلغ ۵۰۰ هزار ریال برسد، به درخواست شما تسویه خواهد شد.
                </p>
            </div>

        </div>

        <div class="row intro center-horizontally">

            <table class="affiliate-info">
                <thead>
                    <tr>
                        <th colspan="3'">تعرفه کارمزد وب‌پی</th>
                        <th class="highlight">کارمزد معرف</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>درصد کارمزد</td>
                        <td>سقف کارمزد</td>
                        <td>کف کارمزد</td>
                        <td class="highlight" rowspan="2">۲۰٪ از کارمزد وب‌پی</td>
                    </tr>
                    <tr>
                        <td>۱٪</td>
                        <td>۶۰۰۰تومان</td>
                        <td>۱۵۰تومان</td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div class="row intro center-horizontally">

            <p class="title">
                توجه داشته باشید که...
            </p>
            <p class="description">
                برای همکاری و کسب درآمد از وب‌پی حتما باید در سرویس یک حساب کاربری تعریف کنید و اطلاعات خود را از قبیل شماره موبایل و شماره شبا در سیستم ثبت کنید.
                این اطلاعات به صورت کاملا محرمانه نزد ما محفوظ خواهد بود و صرفا برای شناسایی شخص معرف و تسویه حساب از آن استفاده می‌شود.
            </p>

        </div>

        <div class="row intro center-horizontally">
            <button class="action" @click="goToLogin">
                <span class="title">همین الان شروع کن</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'webpay-pages-affiliation',
    data: function() {
        return {}
    },

    methods: {
        goToLogin() {
            this.$router.push({name: 'login'})
        }
    },
}
</script>

<style scoped>

.affiliate-info {
    margin: 10px auto;
    border: 2px solid var(--color-table-border);
    border-collapse: collapse;
}

.affiliate-info td, th {
    padding: 5px 5px;
    border: 1px solid var(--color-table-border);
    text-align: center;
}

@media (min-width: 550px) {
    .affiliate-info td, th {
        padding: 5px 40px;
    }
} 

.affiliate-info td.highlight, th.highlight {
    background-color: var(--color-primary);
}

.intro {
    display: flex;
    flex-wrap: wrap;
    margin: 15px auto;
}

.v-align-top {
    align-items: flex-start;
}

.v-align-center {
    align-items: center;
}

.intro p.title {
    font-size: 1.4em;
    font-weight: 600;
    color: var(--color-primary);
    margin: 1em 20px 0.5em 20px;
}

.intro p.title.reduced-size {
    font-size: 1.2em;
}

.intro p.description {
    font-size: 1.2em;
    color: var(--text-primary);
    margin: 0em 20px 1.5em 20px;
}

.intro ul, ol {
    margin: 0em 50px 1.5em 20px;
    text-align: justify;
    font-size: 1.2em;
}

.intro li {
    font-weight: 600;
}

.affiliate-banner-image img {
    width: 100%;
    margin-top: 1em;
}

.affiliate-column-image {
    width: 150px;
    height: 150px;
    margin: 0 auto;
}

.affiliate-column-image img {
    /* width: 70%; */
    height: 70%;
}

</style>