export const plugins = [
    {
        id: "wordpress-woocommerce",
        image: "/img/landing/plugins/woocommerce-logo.webp?v=2",
        title: "WooCommerce",
        summary: "افزونه وب‌پی در ووکامرس / وردپرس",
        description: "افزونه ارتباط با درگاه وب‌پی برای فروشگاه‌ساز ووکامرس بر روی پلتفرم وردپرس",
        info: "این افزونه برای کاربرانی مناسب است که از فروشگاه‌ساز شناخته‌شده «ووکامرس» که در محیط «وردپرس» کار ‌می‌کند " +
              "استفاده می‌کنند. این افزونه با هر دو نسخه انگلیسی و فارسی ووکامرس قابل استفاده است.",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayWoocommerce.mp4"},
            {type: 'md', src:"/guide/wordpress-woocommerce.md"},
        ],
        download: "/plugin/bahamta-webpay-wooc-1.0.zip",
        color: "rgba(154, 94, 142, 0.7)",
    },
    {
        id: "wordpress-edd",
        image: "/img/landing/plugins/edd-logo.webp?v=2",
        title: "EDD",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی برای فروش فایل‌های دیجیتال بر روی ای‌دی‌دی",
        info: "این افزونه برای کاربرانی است که با استفاده از EDD  به مخاطبان خود محتوای دیجیتالی " +
              "(مثل انواع فایل‌های صوتی یا تصویری) می‌فروشند.",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayEdd.mp4"},
            {type: 'md', src:"/guide/wordpress-edd.md"},
        ],
        download: "/plugin/bahamta-webpay-edd-1.0.zip",
        color: "rgba(25, 34, 25, 0.7)",
    },
    {
        id: "rsform",
        image: "/img/landing/plugins/rsform-logo.webp?v=2",
        title: "RSForm",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی برای محیط آراس‌فرم جوملا",
        info: "این افزونه برای کاربرانی مناسب است که از افزونه RSForm که در محیط «جوملا» کار می‌کند استفاده می‌کنند. ",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayRSForm.mp4"},
            {type: 'md', src:"/guide/joomla-rsform.md"},
        ],
        download: "/plugin/bahamta-webpay-rsform-1.0.zip",
        color: "rgba(22, 135, 184, 0.7)",
    },
    {
        id: "whmcs",
        image: "/img/landing/plugins/whmcs-logo.webp?v=2",
        title: "WHMCS",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی بر روی پلتفرم WHMCS",
        info: "این افزونه برای استفاده از «وب‌پی» به عنوان درگاه پرداخت در محیط WHMCS مناسب است. ",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayWHMCS.mp4"},
            {type: 'md', src:"/guide/whmcs.md"},
        ],
        download: "/plugin/bahamta-webpay-whmcs-1.0.zip",
        color: "rgba(120, 192, 74, 0.7)",
    },
    {
        id: "prestashop_16",
        image: "/img/landing/plugins/prestashop-logo.webp?v=2",
        title: "PrestaShop 1.6",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی بر روی پلتفرم PrestaShop نسخه 1.6",
        info: "این افزونه برای استفاده از درگاه پرداخت «وب‌پی» در محیط فروشگاه‌ساز PrestaShop نسخه 1.6 (و قبل از آن) " +
              "مناسب است. برای استفاده در نسخه 1.7 فروشگاه‌ساز PrestaShop از افزونه دیگری که برای این نسخه " +
              "تهیه شده است استفاده نمایید.",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayPresta16.mp4"},
            {type: 'md', src:"/guide/presta-16.md"},
        ],
        download: "/plugin/bahamta-webpay-prestashop16-1.0.zip",
        color: "rgba(221, 3, 109, 0.7)",
    },
    {
        id: "prestashop_17",
        image: "/img/landing/plugins/prestashop-logo.webp?v=2",
        title: "PrestaShop 1.7",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی بر روی پلتفرم PrestaShop نسخه 1.7",
        info: "این افزونه برای استفاده از درگاه پرداخت «وب‌پی» در محیط فروشگاه‌ساز PrestaShop نسخه 1.7 مناسب است. " +
              "برای استفاده در نسخه 1.6 (و قبل از آن) فروشگاه‌ساز PrestaShop از افزونه دیگری که برای این نسخه " +
              "تهیه شده است استفاده نمایید.",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayPresta17.mp4"},
            {type: 'md', src:"/guide/presta-17.md"},
        ],
        download: "/plugin/bahamta-webpay-prestashop17-1.0.zip",
        color: "rgba(221, 3, 109, 0.7)",
    },
    {
        id: "opencart_23",
        image: "/img/landing/plugins/opencart-logo.webp?v=2",
        title: "OpenCart 2.3",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی بر روی پلتفرم OpenCart نسخه 2.3",
        info: "این افزونه برای استفاده از درگاه پرداخت «وب‌پی» در محیط فروشگاه‌ساز OpenCart نسخه 2.3 مناسب است. " +
              "برای استفاده در نسخه 3.0 فروشگاه‌ساز OpenCart از افزونه دیگری که برای این نسخه " +
              "تهیه شده است استفاده نمایید.",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayOpenCart.mp4"},
            {type: 'md', src:"/guide/opencart.md"},
        ],
        download: "/plugin/bahamta-webpay-opencart23-1.0.zip",
        color: "rgba(192, 228, 239, 0.7)",
    },
    {
        id: "opencart_30",
        image: "/img/landing/plugins/opencart-logo.webp?v=2",
        title: "OpenCart 3.0",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی بر روی پلتفرم OpenCart نسخه 3.0",
        info: "این افزونه برای استفاده از درگاه پرداخت «وب‌پی» در محیط فروشگاه‌ساز OpenCart نسخه 3.0 مناسب است. " +
              "برای استفاده در نسخه 2.3 فروشگاه‌ساز OpenCart از افزونه دیگری که برای این نسخه " +
              "تهیه شده است استفاده نمایید.",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayOpenCart.mp4"},
            {type: 'md', src:"/guide/opencart.md"},
        ],
        download: "/plugin/bahamta-webpay-opencart30-1.0.zip",
        color: "rgba(192, 228, 239, 0.7)",
    },
    {
        id: "gravity_froms",
        image: "/img/landing/plugins/gravity-forms-logo.webp?v=1",
        title: "Gravity Froms",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی برای Gravity Forms در محیط وردپرس",
        info: "این افزونه برای استفاده از «وب‌پی» به عنوان درگاه پرداخت در محیط Gravity Forms در پلتفورم وردپرس مناسب است. ",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayGravityForms.mp4"},
            {type: 'md', src:"/guide/gravity-forms.md"},
        ],
        download: "/plugin/bahamta-webpay-gravity-forms-1.0.zip",
        color: "rgba(227, 43, 26, 0.7)",
    },
    {
        id: "freer",
        image: "/img/landing/plugins/freer-logo.webp?v=3",
        title: "Virtual Freer",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی برای Virtual Freer",
        info: "این افزونه برای استفاده از «وب‌پی» به عنوان درگاه پرداخت در محیط Virtual Freer مناسب است. ",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayFreer.mp4"},
            {type: 'md', src:"/guide/freer.md"},
        ],
        download: "/plugin/bahamta-webpay-freer-1.0.zip",
        color: "rgba(236, 166, 93, 0.7)",
    },
    {
        id: "virtuemart",
        image: "/img/landing/plugins/virtuemart-logo.webp?v=3",
        title: "VirtueMart",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی برای VirtueMart در پلتفورم جوملا",
        info: "این افزونه برای استفاده از «وب‌پی» به عنوان درگاه پرداخت در محیط VirtueMart در پلتفورم جوملا مناسب است. ",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayVirtueMart.mp4"},
            {type: 'md', src:"/guide/joomla-virtuemart.md"},
        ],
        download: "/plugin/bahamta-webpay-virtuemart-1.0.zip",
        color: "rgba(36, 83, 142, 0.7)",
    },
    {
        id: "donation",
        image: "/img/landing/plugins/donation-logo.webp?v=3",
        title: "Donation",
        summary: "",
        description: "افزونه ارتباط با درگاه وب‌پی برای جمع‌آوری حمایت مالی در محیط وردپرس",
        info: "این افزونه برای استفاده از «وب‌پی» به عنوان درگاه پرداخت در محیط وردپرس و جمع‌آوری حمایت مالی مناسب است. ",
        guide: [
            {type: 'mp4', src:"/video/landing/plugins/WebpayDonation.mp4"},
            {type: 'md', src:"/guide/donation.md"},
        ],
        download: "/plugin/bahamta-webpay-donation-1.1.zip",
        color: "rgba(188, 52, 38, 0.7)",
    },
    {
        id: "laravel_1",
        image: "/img/landing/plugins/laravel-logo.webp?v=2",
        title: "Laravel [1]",
        summary: "کتابخانه ارتباط با درگاه پرداخت وب‌پی در محیط لاراول (رضوی‌پناه)",
        description: "برنامه‌نویسانی که در محیط «لاراول» برنامه‌نویسی می‌کنند، می‌توانند از این کتابخانه " +
                     "برای ارتباط با درگاه پرداخت وب‌پی استفاده کنند. این کتابخانه توسط آقای «دانیال رضوی پناه» توسعه " +
                     "و به صورت رایگان در اختیار برنامه‌نویسان دیگر قرار داده شده است.",
        info: "این کتابخانه توسط برنامه‌نویسان حرفه‌ای به صورت خودجوش پیاده‌سازی و بر روی github قرار گرفته است، " +
              "تا سایر برنامه‌نویسان نیز بتوانند آن را بهبود دهند و یا از آن استفاده نمایند. به همین خاطر " +
              "باهمتا نظارتی بر این تغییرات و تست‌های انجام شده ندارد.",

        link: {title: "github", url: "https://github.com/danialrp/laravel-webpay"},
        color: "rgba(237, 62, 52, 0.7)",
    },
    {
        id: "laravel_2",
        image: "/img/landing/plugins/laravel-logo.webp?v=2",
        title: "Laravel [2]",
        summary: "کتابخانه ارتباط با درگاه پرداخت وب‌پی در محیط لاراول (پارسی سولوشن)",
        description: "برنامه‌نویسانی که در محیط «لاراول» برنامه‌نویسی می‌کنند، می‌توانند از این کتابخانه " +
                     "برای ارتباط با درگاه پرداخت وب‌پی استفاده کنند. این کتابخانه توسط شرکت «پارسی سولوشن» توسعه " +
                     "و به صورت رایگان در اختیار برنامه‌نویسان دیگر قرار داده شده است.",
        info: "این کتابخانه توسط برنامه‌نویسان حرفه‌ای به صورت خودجوش پیاده‌سازی و بر روی github قرار گرفته است، " +
              "تا سایر برنامه‌نویسان نیز بتوانند آن را بهبود دهند و یا از آن استفاده نمایند. به همین خاطر " +
              "باهمتا نظارتی بر این تغییرات و تست‌های انجام شده ندارد.",

        link: {title: "github", url: "https://github.com/parsisolution/gateway"},
        color: "rgba(237, 62, 52, 0.7)",
    },
    {
        id: "python",
        image: "/img/landing/plugins/python-logo.webp?v=2",
        title: "Python",
        summary: "کتابخانه ارتباط با درگاه پرداخت وب‌پی در محیط پایتون",
        description: "برنامه‌نویسانی که در محیط «پایتون» برنامه‌نویسی می‌کنند، می‌توانند از این کتابخانه " +
                     "برای ارتباط با درگاه پرداخت وب‌پی استفاده کنند. این کتابخانه توسط آقای «امیرحسین فلاحتی» در کمپین " +
                     "«المپیک باهمتا» پیاده‌سازی و برکزیده شده است.",
        info: "این کتابخانه توسط برنامه‌نویسان حرفه‌ای و در کمپین «المپیک باهمتا» پیاده‌سازی شده و بر روی github قرار گرفته است، " +
              "تا سایر برنامه‌نویسان نیز بتوانند آن را بهبود دهند و یا از آن استفاده نمایند.",

        link: {title: "github", url: "https://github.com/amirho3inf/python-webpay"},
        color: "rgba(254, 209, 58, 0.7)",
    },
    {
        id: "code_igniter_3",
        image: "/img/landing/plugins/codeigniter3-logo.webp?v=2",
        title: "CodeIgniter 3",
        summary: "کتابخانه ارتباط با درگاه پرداخت وب‌پی در محیط CodeIgniter 3",
        description: "برنامه‌نویسانی که در محیط «کد ایگنیتر ۳» برنامه‌نویسی می‌کنند، می‌توانند از این کتابخانه " +
                     "برای ارتباط با درگاه پرداخت وب‌پی استفاده کنند. این کتابخانه توسط آقای «امید آقایی» در کمپین " +
                     "«المپیک باهمتا» پیاده‌سازی و برکزیده شده است.",
        info: "این کتابخانه توسط برنامه‌نویسان حرفه‌ای و در کمپین «المپیک باهمتا» پیاده‌سازی شده و بر روی github قرار گرفته است، " +
              "تا سایر برنامه‌نویسان نیز بتوانند آن را بهبود دهند و یا از آن استفاده نمایند.",

        link: {title: "github", url: "https://github.com/omid-aghaei/CI_Payweb_Library"},
        color: "rgba(236, 67, 35, 0.7)",
    },
]

export function findPlugin(id) {
    return plugins.find(function(element) {
        return element.id === id
    })
}
