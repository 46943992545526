import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fa from 'vuetify/es5/locale/fa'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { fa },
    current: "fa",
  },
  icons: {
    iconfont: "mdi",
  },
  rtl: true,
  theme: {
    themes: {
      light: {
        primary: "#49b4af",
        secondary: "#242993",
        accent: "#f6b437",
      },
    },
    options: {
      customProperties: true,
    },
  },
});
