export const snippets = [
    {
        id: "php",
        image: "/img/landing/plugins/php-logo.webp?v=2",
        title: "PHP",
        summary: "نمومه کد پی اچ پی",
        description: "در این نمونه کد، سعی شده تمام مراحل مورد نیاز برای ارسال درخواست، دریافت callback و نیز دریافت تأیید پرداخت نشان داده شود.",
        info: "توضیحات کامل در این مورد را می‌توانید در کامنت‌های داخل کد بخوانید.",
        link: "https://github.com/bahamta/webpay/tree/master/php",
        color: "rgba(139, 148, 188, 0.7)",
    },
]

export function findSnippet(id) {
    return snippets.find(function(element) {
        return element.id === id
    })
}
