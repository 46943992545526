<template>
    <header class="header">
        <div class="container">
            <nav class="menu">
                <div class="right">
                    <slot name="right"></slot>
                </div>
                <div class="center">
                    <slot name="center"></slot>
                </div>
                <div class="left">
                    <slot name="left"></slot>
                </div>
            </nav>
        </div>
    </header>
</template>

<script>
export default {
    name: "webpay-header-base",
}
</script>

<style scoped>
header.header {
    /* position: relative; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    position: fixed;
    background-color: white;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 0 16px 0;
}

header.header .menu,
header.header .menu .right,
header.header .menu .center,
header.header .menu .left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

header.header .menu .right,
header.header .menu .center,
header.header .menu .left {
    width: 33.33%;
}

header.header .menu .right {
    justify-content: flex-start;
}

header.header .menu .left {
    justify-content: flex-end;
}
</style>