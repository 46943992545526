<template>

    <section class="general-text input-component">
        <label>{{ label }} <span v-if="isForced" class="forced">*</span></label>

        <input
            class="field general-text"
			:class="align"
            v-model="fieldData"
            @blur="checkValue"
            :placeholder="placeHolder"
			ref="fieldData"
            :disabled=false>

        <p v-show="errorMessage" class="input-error">{{ errorMessage }}</p>

    </section>

</template>

<style scoped>

.left {
	text-align: left;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

</style>

<script>
export default {
	name: 'general-text',

	props: {
		label: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		placeHolder: {
			type: String,
			default: '',
		},
		align: {
			validator: function (value) {
				// The value must match one of these strings
				return ['', 'left', 'center', 'right'].indexOf(value) !== -1
			}
		},
		initValue: {
			type: String,
			default: '',
		},
		isForced: {
			type: Boolean,
			default: false,
		},
		convertPersian: {
			type: Boolean,
			default: true,
		}
	},

	data() {
		return {
			fieldData: this.initValue,
			errorMessage: '',
		}
	},

	computed: {
		fieldName() {
			return (this.name === '') ? this.label : this.name
		},
	},

	methods: {
		checkValue() {
            this.errorMessage = ''
            if (this.fieldData.trim() === '') {
                if (this.isForced)
                    this.errorMessage = '«' + this.fieldName + '» نباید خالی باشد'
            }
		},

		setValue(value) {
			this.fieldData = value
		},

		setError(message) {
			this.errorMessage = message
		},

		hasError() {
			return (this.errorMessage != '')
		},

		toString() {
			return this.fieldData
		},
		
		focus() {
			this.$refs.fieldData.focus()
		},
	},

	watch: {
        fieldData(value) {
			if (this.convertPersian) {
				this.fieldData = this.toPersianDigits(value)
			}
        }
	}
}
</script>