<template>
  <div>
    <webpay-header-landing :loggedIn="loggedIn" />

    <main class="container">
      <div class="dummy-band"></div>
      <div class="client-tabs center-align-cell">
        <router-link
          class="client-tab"
          :to="{ name: 'plugins' }"
          :class="{ selected: section === 'plugins' }"
        >
          <div>
            <svg class="client-icon" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M22,13.5C22,15.26 20.7,16.72 19,16.96V20A2,2 0 0,1 17,22H13.2V21.7A2.7,2.7 0 0,0 10.5,19C9,19 7.8,20.21 7.8,21.7V22H4A2,2 0 0,1 2,20V16.2H2.3C3.79,16.2 5,15 5,13.5C5,12 3.79,10.8 2.3,10.8H2V7A2,2 0 0,1 4,5H7.04C7.28,3.3 8.74,2 10.5,2C12.26,2 13.72,3.3 13.96,5H17A2,2 0 0,1 19,7V10.04C20.7,10.28 22,11.74 22,13.5M17,15H18.5A1.5,1.5 0 0,0 20,13.5A1.5,1.5 0 0,0 18.5,12H17V7H12V5.5A1.5,1.5 0 0,0 10.5,4A1.5,1.5 0 0,0 9,5.5V7H4V9.12C5.76,9.8 7,11.5 7,13.5C7,15.5 5.75,17.2 4,17.88V20H6.12C6.8,18.25 8.5,17 10.5,17C12.5,17 14.2,18.25 14.88,20H17V15Z"
              />
            </svg>
            افزونه‌ها
          </div>
        </router-link>
        <router-link
          class="client-tab"
          :to="{ name: 'snippets' }"
          :class="{ selected: section === 'snippets' }"
        >
          <div>
            <svg class="client-icon" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M9.54 15.65L11.63 17.74L10.35 19L7 15.65L10.35 12.3L11.63 13.56L9.54 15.65M17 15.65L13.65 19L12.38 17.74L14.47 15.65L12.38 13.56L13.65 12.3L17 15.65Z"
              />
            </svg>
            نمونه کدها
          </div>
        </router-link>
      </div>
      <div>
        <router-view @section="setSection"></router-view>
      </div>
    </main>

    <webpay-footer-landing />
  </div>
</template>

<script>
import WebpayHeaderLanding from "../headers/WebpayHeaderLanding.vue";
import WebpayFooterLanding from "../footers/WebpayFooterLanding.vue";

export default {
  name: "webpay-clients",

  components: {
    WebpayHeaderLanding,
    WebpayFooterLanding,
  },

  data() {
    return {
      section: "",
    };
  },

  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },

  methods: {
    setSection(section) {
      this.section = section;
    },
  },
};
</script>

<style scoped>
.client-tabs {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.client-tab {
  flex-grow: 1;
  padding: 24px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.client-icon {
  margin-bottom: -8px;
  height: 24px;
}

.tab-button {
  padding: 8px;
}

.client-tab > *,
.client-icon {
  color: dimgray;
  fill: dimgray;
}

.selected {
  border-bottom: 3px solid var(--color-primary);
}

.selected * {
  color: var(--color-primary);
  fill: var(--color-primary);
}
</style>
