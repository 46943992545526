<template>
    <webpay-header-base class="bring-up">

        <template v-slot:right>
            <router-link to="/">
                <img
                    class="header-item bahamta-logo"
                    src="/img/bahamta/logo.svg?v=2">
            </router-link>
        </template>

    </webpay-header-base>
</template>

<script>
import WebpayHeaderBase from './WebpayHeaderBase.vue'

export default {
    name: 'webpay-header-login',
    components: {
        WebpayHeaderBase,
    },
    data() {return{
        
    }},
    computed: {
        userName() {
            return this.$store.getters.userFirstName
        },
    },
}
</script>

<style scoped>
.bring-up {
    z-index: 10;
}

.header-item {
    height: 45px;
    margin: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--color-primary);
}

.header-item.bahamta-logo {
    height: 30px;
}

.webpay-name {
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 800;
}

@media (min-width: 550px) {
    .header-item {
        height: 60px;
    }

    .header-item.bahamta-logo {
        height: 45px;
    }

    .webpay-name {
        font-size: 22px;
    }
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}
</style>