<template>
    <footer id="landing-footer" class="container footer">
        <div class="row">
            <div class="eight columns">
                <p>باهمتا یک سرویس پرداخت و دریافت پول برای کسب و کارها و اشخاص است که تمرکز آن بر روی امنیت و سادگی است.</p>
                      <div class="declaration">
                        <img src="/img/logo-lightgray.svg?1" height="25">
                        <p>
                            پرداخت‌یار رسمی بانک مرکزی
                        </p>
                    </div>
                <!-- <p>
                    تحت نظارت شاپرکِ بانک مرکزی
                    <img src="/img/shaparak.svg?1" height="30" align="top">
                    <img src="/img/cbi.svg?1" height="30" align="top">
                </p> -->
            </div>
            <div class="four columns">
                <ul class="support">
                    <li><a href="tel:+982191092580">۰۲۱ ۹۱۰۹ ۲۵۸۰</a></li>
                    <li><a href="mailto:info@bahamta.com">info@bahamta.com</a></li>
                </ul>
                <ul class="social">
                    <li>
                        <a href="https://twitter.com/bahamta">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512.002 512.002" xml:space="preserve"><g><path d="M512.002,97.211c-18.84,8.354-39.082,14.001-60.33,16.54c21.686-13,38.342-33.585,46.186-58.115   c-20.299,12.039-42.777,20.78-66.705,25.49c-19.16-20.415-46.461-33.17-76.674-33.17c-58.011,0-105.042,47.029-105.042,105.039   c0,8.233,0.929,16.25,2.72,23.939c-87.3-4.382-164.701-46.2-216.509-109.753c-9.042,15.514-14.223,33.558-14.223,52.809   c0,36.444,18.544,68.596,46.73,87.433c-17.219-0.546-33.416-5.271-47.577-13.139c-0.01,0.438-0.01,0.878-0.01,1.321   c0,50.894,36.209,93.348,84.261,103c-8.813,2.399-18.094,3.687-27.674,3.687c-6.769,0-13.349-0.66-19.764-1.888   c13.368,41.73,52.16,72.104,98.126,72.949c-35.95,28.176-81.243,44.967-130.458,44.967c-8.479,0-16.84-0.496-25.058-1.471   c46.486,29.807,101.701,47.197,161.021,47.197c193.211,0,298.868-160.062,298.868-298.872c0-4.554-0.104-9.084-0.305-13.59   C480.111,136.775,497.92,118.275,512.002,97.211z"></path></g></svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://instagram.com/bahamta">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 169.063 169.063" xml:space="preserve"><g><path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752   c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407   c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752   c17.455,0,31.656,14.201,31.656,31.655V122.407z"></path><path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561   C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561   c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"></path><path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78   c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78   C135.661,29.421,132.821,28.251,129.921,28.251z"></path></g></svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://t.me/bahamta">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 300 300" xml:space="preserve" width="512px" height="512px"><g id="XMLID_496_"><path id="XMLID_497_" d="M5.299,144.645l69.126,25.8l26.756,86.047c1.712,5.511,8.451,7.548,12.924,3.891l38.532-31.412   c4.039-3.291,9.792-3.455,14.013-0.391l69.498,50.457c4.785,3.478,11.564,0.856,12.764-4.926L299.823,29.22   c1.31-6.316-4.896-11.585-10.91-9.259L5.218,129.402C-1.783,132.102-1.722,142.014,5.299,144.645z M96.869,156.711l135.098-83.207   c2.428-1.491,4.926,1.792,2.841,3.726L123.313,180.87c-3.919,3.648-6.447,8.53-7.163,13.829l-3.798,28.146   c-0.503,3.758-5.782,4.131-6.819,0.494l-14.607-51.325C89.253,166.16,91.691,159.907,96.869,156.711z"></path></g></svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    </footer>
</template>

<script>
export default {
    name: "webpay-footer-landing"
}
</script>

<style scoped>
#landing-footer.footer {
    margin-top: 60px;
}

#landing-footer.footer p,
#landing-footer.footer ul,
#landing-footer.footer li {
    color: #bbb;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

#landing-footer.footer .social svg {
    height: 25px;
    width: 25px;
}

#landing-footer.footer .social li {
    display: inline;
    margin: 4px;
}

#landing-footer.footer a:hover {
    fill: #49b4af;
    color: #49b4af;
}

#landing-footer.footer a {
    fill: #667683;
    color: #667683;
}

#landing-footer.footer li {
    list-style: none;
    margin-bottom: .5rem;
}

#landing-footer.footer .support, #landing-footer.footer .social {
    direction: ltr;
    text-align: center;
    margin-bottom: 1rem;
    padding: 0;
}

.declaration {
  display: flex;
  flex-wrap: wrap;
}

.declaration img {
  margin-left: 0.3rem;
}

@media (min-width: 550px) {
    #landing-footer.footer .support, #landing-footer.footer .social {
        text-align: left;
    }
}
</style>