<template>
    <div>
        <webpay-header-landing/>

        <main class="container">
            <div class="dummy-band">
            </div>

            <div class="doc-content">
                <slot name="doc-content"></slot>
            </div>

        </main>

        <webpay-footer-landing class="push-down-for-header" />
    </div>
</template>

<script>
import WebpayHeaderLanding from '../headers/WebpayHeaderLanding.vue';
import WebpayFooterLanding from '../footers/WebpayFooterLanding.vue';

export default {
    name: "webpay-doc",

    components: {
        WebpayHeaderLanding,
        WebpayFooterLanding
    },
}
</script>

<style>
    @import url('/css/document.css');
</style>