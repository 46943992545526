import axios from "axios"

export default {
  namespaced: true,
  state: {
    lastAccountOwner: '',
    lastWalletName: '',
  },
  mutations: {
    clearAccountOwner(state) {
      state.lastAccountOwner = ''
    },
    setAccountOwner(state, accountOwner) {
      state.lastAccountOwner = accountOwner
    },
    clearWalletName(state) {
      state.lastWalletName = ''
    },
    setWalletName(state, name) {
      state.lastWalletName = name
    },
  },
  getters: {
    accountOwner(state) {
      return state.lastAccountOwner
    },
    walletName(state) {
      return state.lastWalletName
    },
  },
  actions: {
    async obtainAccountOwner({commit, rootGetters}, params) {
      commit('clearAccountOwner')
      params.key = rootGetters.sessionKey
      await axios.get('/api/owner/account_owner', {params})
        .catch(error => {
          if (error.response) {
            alert(error.response.config.url + " => " + error.response.data)
          } else if (error.request) {
            alert(error.request.config.url + " => no response")
          } else {
            alert(error.message)
          }
        }).then(response => {
          if (response == null || response.data == null)
            return null

          return response.data
        }).then(data => {
          if (data == null) {
            throw 'no_data'
          }

          if (data.ok) {
            // commit('update', data.result)
            commit('setAccountOwner', data.result.name)
            return data.result
          }

          switch (data.error) {
            case 'NOT_AUTHORIZED':
              break
            default:
              throw data.error
          }
        })
    },
    async findWalletByAddress({commit, rootGetters}, params) {
      commit('clearWalletName')
      params.key = rootGetters.sessionKey
      await axios.get('/api/owner/wallet_by_address', {params})
        .catch(error => {
          if (error.response) {
            alert(error.response.config.url + " => " + error.response.data)
          } else if (error.request) {
            alert(error.request.config.url + " => no response")
          } else {
            alert(error.message)
          }
        }).then(response => {
          if (response == null || response.data == null)
            return null
  
          return response.data
        }).then(data => {
          if (data == null) {
            return
          }
  
          if (data.ok) {
            commit('setWalletName', data.result.name)
            return
          }
  
          switch (data.error) {
            case 'NOT_AUTHORIZED':
            case 'UNKNOWN_WALLET_ADDRESS':
              throw data.error

            default:
              alert('unknown error for service/findWalletByAddress action: ' + data.error)
              break
          }
        })
    },
  },
}