<template>
    <nav
        :class="{open: isOpen}">
        <span
            @click="$emit('mobile-side-nav-close')">
            &times;
        </span>

        <div>
            <slot></slot>
        </div>
    </nav>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>
nav {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: var(--color-primary);
    z-index: 100;
    overflow-x: hidden;
    top: 0;
    left: -100%;
    padding-top: 75px;
    transition: 0.5s;
}

/* close button */
nav > span {
    position: absolute;
    font-size: 45px;
    line-height: 31px;
    top: 15px;
    left: 18px;
    text-decoration: none;
    color: white;
    display: block;
    transition: 0.3s;
}

/* links */
#css-container nav > div > .mobile-nav-link {
    margin: 8px 8px 32px 8px;
    text-decoration: none;
    font-size: 25px;
    color: white;
    display: block;
    transition: 0.3s;
    text-align: center;
}

nav > div > .mobile-nav-link.bottom {

}

.open {
    left: 0;
}
</style>