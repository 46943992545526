<template>
    <webpay-doc>
        <template v-slot:doc-content>
            <div v-html="htmlCont" class="faq-content">
            </div>
        </template>
    </webpay-doc>
</template>

<script>
import axios from 'axios';
import WebpayDoc from './WebpayDoc.vue';

export default {
    name: "webpay-doc-faq",

    components: {
        WebpayDoc,
    },

    data() {
        return {
            html: '',
        }
    },

    computed: {
        htmlCont: {
            get: function() {
                return this.html
            },

            set: function(value) {
                this.html = value
            },
        },
    },

    created() {
        this.getDoc().then(obj => {
            this.htmlCont = obj
        })
    },
    
    methods: {
        getDoc() {
            
            this.$store.commit('addLoading', 'get_doc_faq')
            const emptyContent = '<body><h2>دسترسی به مستندات با خطا روبرو شد</h2></body>'
            return axios.get('/api/faq', {
                params: {}
            }).then(response => {

                if (response == null || response.data == null)
                    return emptyContent

                let parser = new DOMParser()
                let doc = parser.parseFromString(response.data, 'text/html')
                let bodyElem = doc.getElementsByTagName('body')

                return bodyElem.item(0).innerHTML
            }).catch(error => {
                if (error.response) {
                    console.log(error.response.config.url + " => " + error.response.data)
                } else if (error.request) {
                    console.log(error.request.config.url + " => no response")
                } else {
                    console.log(error.message)
                }
                return emptyContent
            }).finally(() => {
                this.$store.commit('removeLoading', 'get_doc_faq')
            })
        },
    },
}
</script>
