import Vue from "vue";
import router from "./plugins/router";
import store from "./plugins/store";
import vuetify from "./plugins/vuetify";
import "./plugins/utils";

Vue.config.productionTip = false;

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

import VueGtm from "vue-gtm";
Vue.use(VueGtm, {
  id: "GTM-P3BSSSV",
  vueRouter: router,
  // @TODO: to be discussed in technical team for enhancement (enable/disable and debug)
  // enabled: ?, // can be enabled or disabled
  // debug: isDebug, // can be set through environment variable, e.g.: const isDebug = process.env.DEBUG === 'true'
});

import App from "./App.vue";
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,   
  vuetify,
  store,
});
